.master-media-filter-container {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

#media-master-page-client-row-root {
    .header-container {
        display: grid;
        grid-template-columns: 0.9fr 0.1fr ;
        padding: 10px;
    }
    .media-invoice-table {
        font-family: 'Roboto';
        width: 100%;
    }
    .align-self-center {
        align-self: center;
        left: auto;
        right: auto;
    }

    .badge {
        display: inline-block;
        padding: 0.7em;
        font-size: 70%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .2rem;
    }

    .badge-warning {
        text-transform: uppercase;
        height: 20px;
        line-height: 21px;
        color: white;
        background-color: #ffa43b;
        padding: 0px 1rem 0px 1rem;
        height: auto;
    }

    .badge-success {
        text-transform: uppercase;
        height: 20px;
        line-height: 21px;
        color: white;
        background-color: #1bc943;
        padding: 0px 1rem 0px 1rem;
        height: auto;
    }
}