/* .media-ads-table-checkbox-header {
  width: 70px !important;
  padding: 0px !important;
} */

.media-ad-table-body-checkbox {
  padding: 0px !important;
}

.media-ads-table-header-cell {
  font-family: Arial, Helvetica, sans-serif;
  height: 40px;
  position: sticky !important;
  /* display: flex; */
}

.media-ads-table-client-header-cell .table-filter-parent {
  position: absolute !important;
  left: 45px !important;
  top: 10% !important;
  margin-top: 10px !important;
  z-index: 10 !important;
}

.media-ads-table-client-header-cell {
  max-width: 400px !important;
}

.media-ads-table-platform-header-cell .table-filter-parent {
  position: absolute !important;
  left: 60px !important;
  top: 10% !important;
  margin-top: 10px !important;
  z-index: 10 !important;
}

.media-ads-table-reference-header-cell .table-filter-parent {
  position: absolute !important;
  left: 70px !important;
  top: 10% !important;
  margin-top: 10px !important;
  z-index: 10 !important;
}

.media-ads-table-transaction-date-header-cell .table-filter-parent {
  position: absolute !important;
  left: 120px !important;
  top: 10% !important;
  margin-top: 10px !important;
  z-index: 10 !important;
}

.media-ads-table-amount-header-cell .table-filter-parent {
  position: absolute !important;
  left: 40px !important;
  top: 10% !important;
  margin-top: 10px !important;
  z-index: 10 !important;
}

.media-ads-table-type-header-cell .table-filter-parent {
  position: absolute !important;
  left: 40px !important;
  top: 10% !important;
  margin-top: 10px !important;
  z-index: 10 !important;
}

.media-ads-table-platform-header-cell {
  width: 120px !important;
}

.media-ads-table-platform-header-cell .table-filter-parent {
  position: absolute !important;
  left: 60px !important;
  top: 10% !important;
  margin-top: 10px !important;
  z-index: 10 !important;
}

.media-ads-table-document-header-cell {
  width: 100px !important;
}

.media-ads-table-transaction-date-header-cell {
  width: 180px !important;
}

.media-ads-table-body tr:nth-child(odd) {
  background-color: #efefef;
}

.media-ads-table-body tr:hover {
  background-color: #cce6ff !important;
}

.media-ad-table-body-cell {
  max-height: 50px;
  padding: 10px;
}

.media-ad-table-row-orange {
  background-color: #ffe6e6 !important;
}

.bold-header {
  font-weight: bold !important;
  font-size: medium !important;
}

.margin-10{
  margin: 10px !important;
}

#media-ad-button-edit-modal-container .MuiGrid-item {
  display: flex !important;
  justify-content: center !important;
}

/* #media-ad-table .table-filter-parent {
  position: absolute ;
  left: -15px;
  top: 10% ;
  margin-top: 10px ;
  z-index: 10 ;
} */

#media-ad-table .filter-list {
  position: absolute;
  max-height: 250px;
  overflow: auto;
  max-width: 220px;
  min-width: 170px;
  top: 100%;
  border: 2px solid #8E9193;
  left: auto;
  right: 0;
  z-index: 9999;
}

#media-ad-table .apply-filter {
  position: relative !important;
  padding: 0px !important;
}
