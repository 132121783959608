.MuiAutocomplete-option .blueText{
    /* background-color: #00b2e2; */
    color: #00b2e2;
}

.SelectExisting {
background-color: #c1f0c1 !important;
color: #2d2d2d !important;
}

.EditRecord {
background-color: #ff9e00 !important;
margin-left: 15px !important;
color: white !important;
}

