.delete-output-error-table tr:nth-child(even){
    background-color: #F8F8F8;
}

.delete-output-error-table .MuiTableCell-head {
    background-color: black !important;
    color: white !important;
}

.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer !important;
}