.campaign-influencers-card-root {
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  gap: 10px;
}

.campaign-influencers-card-list-container {
  display: grid;
  grid-template-columns: 1fr;
}

.campaign-influencers-card-add-new-container{
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.campaign-influencers-card-list-item {
  padding: 10px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
}

.campaing-influencers-card-no-record {
  display: flex;
  justify-content: center;
}

.campaign-influencer-card-selected-record {
  border: 1px solid #c4c4c4;
  background-color: #e1e1e1;
}
