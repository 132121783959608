.project-campaign-stepper-root {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 0.1fr 0.9fr;

  .Mui-disabled{
    svg{
        cursor: pointer !important;
    }
  }
}
.project-campaign-stepper-avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  p {
    font-size: 12px;
  }
}
.project-campaign-stepper-root-cursor {
  cursor: pointer !important;
}
.project-campaign-stepper-warning{
  div{
    background: orange !important;
  }
}