#account-contact-details-dialog {
    .title-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .close-icon-container {
        display: flex;
        justify-content: flex-end;
    }

    .dialog-body-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    #split-by-form-group {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: rgba(0, 0, 0, 0.54)
        }
    }

    .MuiFormLabel-root {
        color: rgba(0, 0, 0, 0.54)
    }

    .MuiSwitch-colorPrimary.Mui-checked {
        color: black;
    }

    .MuiRadio-colorSecondary.Mui-checked {
        color: black;
    }

    .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
        background-color: black;
        opacity: 0.7;
    }

    .MuiInputLabel-filled.MuiInputLabel-shrink {
        // color: #4259EB;
        color: black;
    }

    .MuiFilledInput-root {
        background-color: #ffffff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .MuiFilledInput-root:before {
        border-bottom: 0px !important;
    }

    .MuiFilledInput-root:after {
        border-bottom: 0px !important;
    }

    .MuiDialog-paper {
        background-color: #F3F3F3;
    }

    input {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    }
}