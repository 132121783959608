.table-supplier {
  margin-top: 15px;
}

.table-supplier tr:hover {
  background-color: #cce6ff !important;
}

.table-supplier th {
  background-color: #f1f1f1;
  font-size: large;
}

.table-supplier tr:nth-child(even) {
  background-color: #f1f1f1;
}

.supplier-actions {
  max-width: 75px;
}

.supplier-add-fab {
  background-color: #00b2e2 !important;
  color: white !important;
  margin-bottom: "auto";
  margin-left: "auto";
  margin-right: "0px";
  margin-top: "auto";
  min-width: "40px";
  max-width: "40px";
}

.media-suppliers-root {

  .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #4259eb;
  }
  .MuiFilledInput-root:before {
    border-bottom: 0px !important;
  }

  .MuiFilledInput-root:after {
    border-bottom: 0px !important;
  }

  .MuiDialog-paper {
    background-color: #f3f3f3;
  }

  input {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 10px;
  }
}

.media-suppliers-paper {
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  background: #f8f8f8 !important;
}
.media-suppliers-header-container{
  padding: 10px 10px;
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  gap: 10px;
  align-items: end;
}

.media-suppliers-body-container {
  display: grid;
  padding: 20px;
  gap: 5px;
}

.media-suppliers-row {
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  :hover {
    background-color: #ededed !important;
  }

  .media-spend-butget-column-container {
    max-width: 675px;
    
  }
  
  .add-background {
    background-color: white;
    display: flex;
  }
  .text-alignment {
    display: flex;
    align-items: center;
    padding: 0 15px;
    width: 100%;
  }
}

.media-suppliers-table-root {
  .MuiTableCell-root{
    border: 1px solid rgb(138 138 138) !important;
  }
  .MuiTableCell-sizeSmall{
    padding: 0px 24px 0px 16px;
  }
  .MuiInput-underline:before{
    border-bottom: unset !important;
  }
}