.socialites-projects-table td {
  padding: 8px 15px;
  max-height: 50px;
}

.client-home-root {
  padding: 20px 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.client-home-top-container {
  display: flex;
  justify-content: flex-end;
}

.client-home-filter-container {
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  gap: 10px;

  .MuiFilledInput-root {
    background-color: #ffffff !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .MuiFilledInput-root:before {
    border-bottom: 0px !important;
  }

  .MuiFilledInput-root:after {
    border-bottom: 0px !important;
  }
}

.clients-home-link-button {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.clients-home-row-wrapper {
  cursor: pointer;
  display: table-row;
  text-decoration: none;
  vertical-align: middle;
  color: unset !important;
}

.clients-home-row-column-with-inner-link {
  cursor: default;
}

.clients-home-company-name-container {
  width: 420px;
}

.clients-home-flex-container {
  display: flex;
  gap: 5px;
}

.clients-home-badge-column {
  min-width: 150px;
}

.client-libality-section {
  display: grid;
  grid-template-columns: 1fr 0.2fr 0.2fr;
  gap: 10px;

  .MuiFilledInput-root {
    background-color: #ffffff !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .MuiFilledInput-root:before {
    border-bottom: 0px !important;
  }

  .MuiFilledInput-root:after {
    border-bottom: 0px !important;
  }
}