.multi-currency-input-root-container {
  display: grid;
  grid-template-columns: 1fr;
}
.multi-currency-input-field-container {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    gap: 10px;
    // border: 1px solid #c4c4c4;
    // border-radius: 5px;
    align-items: center;
    li {
      list-style-type: none !important;
    }
    .MuiAvatar-root {
      width: 24px !important;
      height: 24px !important;
    }
  }
  .multi-currency-input-link-container{
    display: flex;
    justify-content: flex-end;
  }
  