.preimbursePopUpModal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.preimburse-div {
  margin-top: 30px;
}

.preimburse-table-head {
  background-color: black !important;
}

.preimburse-table-head-cell {
  color: white !important;
  font-size: 1.3rem !important;
  background-color: black !important;
}

.preimburse-items-count {
  margin-top: 10px;
  text-align: end;
}

.preimburse-sendforpayment {
  text-align: end;
}

.preimburse-add-button {
  margin: -5px -20px 0px 0px !important;
  text-align: right;
}

.preimburse-add-button-icon {
    font-size: 35px !important;
}

.remaining-green {
  color: green;
}

.remaining-red {
  color: red;
}