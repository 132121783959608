.navigation-drawer-header {
  background-color: rgb(0, 0, 0);
  color: white;
  font-weight: bold;
  text-align: center;
}

.selected-menu {
  background-color: #c2f0c2 !important;
}

.table-grid {
  margin-top: 45px !important;
}

.typography-text {
  align-self: center !important;
  width: 150px;
}

.button-div {
  text-align: end !important;
}

.textfield-padding {
  margin: 10px 5px 10px 5px;
  display: flex;
}

.textfield-padding-schedule {
  display: flex;
  margin-left: auto;
  margin-right: 0px;
}

.units-label {
  font-size: 10px;
  padding-top: 18px;
  padding-left: 5px;
}

.catering-gst {
  align-self: center;
  margin-left: 15px;
  width: 150px;
}

.text-editor-container {
  background-color: white;
}

.text-editor {
  height: calc(100vh - 255px) !important;
  padding: 0px 15px 15px 15px;
}

.text-editor-schedule {
  height: calc(100vh - 190px) !important;
  padding: 0px 15px 15px 15px;
}

.text-editor-wrapper {
  margin-top: 15px;
}

.contract-button {
  margin-left: 15px !important;
  width: 80px !important;
}

.contracts-buttons-div {
  margin-left: auto;
}

.contracts-buttons-div-schedule {
  align-self: center;
  margin-right: 15px;
}

.surveys-card {
  margin: 5px;
}

.fields-container {
  display: flex;
}

.survey-save-button {
  margin-right: 5px;
}

.switch-box {
  display: flex;
  margin-left: "0";
  margin-right: 0;
  padding: 0px 5px 5px 5px;
  width: 100%;
  justify-content: center;
}
.text-switched {
  border-bottom: solid;
  border-color: #3f51b5;
  padding: 4px;
  font-size: 14px;
  margin-top: 4px;
}
.text-unswitched {
  padding: 4px;
  font-size: 14px;
  margin-top: 4px;
}

.card-title {
  padding: 4px;
}

.extant-surveys-table-head {
  background-color: rgba(0, 0, 0, 0.14);
  color: white;
  border-radius: 4px;
}

.extant-surveys-table {
  margin-right: 5px;
  margin-top: 5px;
}

.spambot-table-head {
  background-color: rgba(0, 0, 0, 0.14);
  color: white;
  border-radius: 4px;
}

.spambot-table {
  margin-right: 5px;
  margin-top: 5px;
}

.paper-sms-types {
    max-height: calc(100vh - 215px) !important;
    overflow: auto !important;
    margin-top: 30px !important;
}

.contract-releases-list {
  max-height: calc(100vh - 165px);
  min-height: calc(100vh - 165px);
  overflow: auto;
  margin-top: 15px;
}

.budget-central-panel-header {
  font-weight: bold !important;
  margin: 10px 5px 10px 10px !important;
  display: flex !important;
  justify-content: center !important;
}

.admin-drawer-list.MuiList-root {
  height: 85%;
  overflow: auto;
}
