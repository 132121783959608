.payable-table-budget-table-category {
  width: 100%;
  .MuiTableCell-head {
    font-weight: 700 !important;
  }
}

.payable-table-budget-table-category-column {
  max-width: 350px;
}

.payable-table-budget-table-sub-table {
  display: grid;
  grid-template-columns: 1fr 0.27fr;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding: 5px;
}
