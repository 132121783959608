.influencer-deliverables-itemwrapper {
  width: 100%;
}

.influencer-deliverables-add-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  button {
    font-size: 10px;
    padding: 10px;
  }
}
.influencer-deliverables-content-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
}