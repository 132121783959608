.person-financial-tab-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.person-financial-tab-flex-container {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  gap: 20px;
}
.person-financial-tab-flex-container-extra-gap {
  gap: 30px;
}

.person-financial-tab-footer {
  display: flex;
  justify-content: flex-end;
}

.person-financial-tab-agent-select-container {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  gap: 20px;
}
