.send-po-dialog-body-container {
  background-color: white;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  width: 100%;
  height: 100%;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}

.send-po-dialog-footer-container {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.send-po-dialog-body-root {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  gap: 20px;
}

.send-po-dialog-iframe-container {
  height: 99%;
}
.send-po-dialog-skeleton-container {
  padding: 20px;
}

.send-po-dialog-supplier-details-wrapper {
  display: flex;
  gap: 25px;
  flex-direction: column;
  padding: 20px;
}
.send-po-dialog-supplier-details {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.send-po-dialog-heading-container {
  font-size: 14px;
}

.send-po-dialog-email-preview-container {
  background-color: #ebebeb !important;
  border: 1px solid #d9d9d9 !important;
  padding: 0 10px;
  border-radius: 4px !important;
  max-height: 350px;
  overflow: auto;
}
