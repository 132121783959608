.equipment-popup-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.equipment-form-label {
  line-height: 2 !important;
  font-weight: 520 !important;
}

.equipment-form-lines {
  margin-top: 15px !important;
}

.equipment-form-button {
  text-align-last: right;
}

.equipment-fee-section-title {
  text-decoration: underline;
  padding-left: 5px;
  color: #404040;
}

.equipment-grid-item-background {
  background-color: #eee;
}

.equipment-button-style-incomplete {
  background-color: rgb(200, 200, 200) !important;
  margin-right: 5px !important;
}

.equipment-button-style-complete {
  background-color: rgb(37, 223, 50) !important;
  color: white !important;
  margin-right: 5px !important;
}
