.reschedule-warning-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.warning-icon {
  color: #ffff80;
}

.reschedule-button {
  margin-left: 15px !important;
}

.reschedule-warning-table > tbody > tr:nth-child(even) {
  background-color: #d9d9d9;
}
.reschedule-warning-table > tbody > tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.reschedule-warning-table-head {
  background-color: #737373;
  text-transform: uppercase;
}

.reschedule-table-head {
  background-color: #737373;
  text-transform: uppercase;
}

.reschedule-table-head-cell {
  color: white !important;
}

.reschedule-warning-table-body {
  background-color: #ccf5ff;
}

.status-signed {
  padding: 5px 12px !important;
  box-sizing: border-box;
  border-radius: 4px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: rgb(0, 128, 0) !important;
}

.status-pending {
  padding: 5px 12px !important;
  box-sizing: border-box;
  border-radius: 4px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: rgb(255, 165, 0) !important;
}

.location-contract-button > div {
  margin-left: 0 !important;
  padding: 0 !important;
}

.location-contract-button > div > div > .MuiButtonGroup-root {
  width: 120px !important;
}

.location-contract-button {
  display: flex !important;
  justify-content: flex-start !important;
}

.vertical-text {
  transform: rotate(270deg) !important;
  // transform: rotate(270deg) translate3d( 0, 0, 0);
  // -webkit-font-smoothing: antialiased !important;
  font-size: 1.1em !important;
  max-width: 30px !important;
}

.vertical-sent {
  // background: #ed9c91 !important;
}

.vertical-not-sent {
  // background: #ffffcc !important;
}

.reschedule-new-date {
  text-align: center !important;
  width: 100% !important;
  margin-bottom: 15px !important;
}

.reschedule-new-date-picker {
  max-width: 150px !important;
}

.reschedule-location-header {
  width: 480px !important;
}

.reschedule-who-header {
  width: 200px !important;
}

.reschedule-category-header {
  width: 100px !important;
}

.reschedule-role-header {
  width: 100px !important;
}

.reschedule-fee-header {
  width: 50px !important;
}

.reschedule-start-time-header {
  width: 50px !important;
}

.reschedule-end-time-header {
  width: 50px !important;
}

//------Match Width with header counterpart--
.reschedule-who-cell {
  width: 200px !important;
}

.reschedule-category-cell {
  width: 100px !important;
}

.reschedule-role-cell {
  width: 100px !important;
}

.reschedule-fee-cell {
  width: 50px !important;
}

.reschedule-start-time-cell {
  width: 50px !important;
}

.reschedule-end-time-cell {
  width: 50px !important;
}

//-----------------
.reschedule-contractor-row:hover {
  background-color: #ade6e6 !important;
}

.reschedule-location-row:hover {
  background-color: #ade6e6 !important;
}

.reschedule-row-shake {
  animation: reschedule-row-shake-animation 1s;
  animation-iteration-count: 1;
}

.reschedule-row-time-out-of-bounds {
    background-color: #ffcccc !important;
}

.reschedule-row-time-out-of-bounds:hover {
    background-color: #ffcccc !important;
}

@keyframes reschedule-row-shake-animation {
    0% {
      transform: translate(0.5px, 0.5px) rotate(0.5deg);
    }
    10% {
      transform: translate(-0.5px, -1px) rotate(-0.5deg);
    }
    20% {
      transform: translate(-1.5px, 0.5px) rotate(0.5deg);
    }
    30% {
      transform: translate(1.5px, 1px) rotate(0deg);
    }
    40% {
      transform: translate(0.5px, -0.5px) rotate(0.5deg);
    }
    50% {
      transform: translate(-0.5px, 1px) rotate(-0.5deg);
    }
    60% {
      transform: translate(-1.5px, 0.5px) rotate(0.5deg);
    }
    70% {
      transform: translate(1.5px, 0.5px) rotate(-0.5deg);
    }
    80% {
      transform: translate(-0.5px, -0.5px) rotate(0.5deg);
    }
    90% {
      transform: translate(0.5px, 1px) rotate(0deg);
    }
    100% {
      transform: translate(0.5px, -1px) rotate(-0.5deg);
    }
  }