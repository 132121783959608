.purchase-order-form-root {
  width: 100%;
}

.purchase-order-form-container {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.purchase-order-form-container-inputs {
  display: flex;
  gap: 10px;
  @media (max-width: 1080px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.purchase-order-form-container-date-input {
  max-width: 180px;
  @media (max-width: 1080px) {
    max-width: unset;
  }
}

.purchase-order-form-container-campaign-input {
  min-width: 250px;
  @media (max-width: 1080px) {
    min-width: unset;
  }
}
.purchase-order-form-container-pull-from-input {
  max-width: 250px;
  @media (max-width: 1080px) {
    max-width: unset;
  }
}

.purchase-order-form-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 15px;
}

.purchase-order-form-flex-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.purchase-order-form-link-btn {
  text-transform: none !important;
}
.purchase-order-form-switch-container {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
}
