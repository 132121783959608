.campaign-form-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.campaign-form-nav-header {
  display: flex;
  justify-content: space-between;
}

.campaign-form-nav-header-btn {
  text-transform: none !important;
}

.client-campaign-flex-container-center {
  display: flex;
  justify-content: center;
}
.client-campaign-flex-container-start {
  display: flex;
  justify-content: start;
  gap: 10px;
}
