.truncate-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.output-field-disabled {
  color: black !important;
}

.output-header {
  font-weight: bold !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-right: 1px solid black !important;
  border-left: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.output-header-days-left {
  width: 30px !important;
}

.output-header-ad-deadline {
  width: 30px !important;
}

.output-header-creative-name {
  width: 350px !important;
}

.output-header-creative-deadline {
  width: 120px !important;
}

.output-header-actions {
  width: 100px !important;
}

.output-table-body {
  border-right: 1px solid black !important;
  border-left: 1px solid black !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.output-table-body-required {
  background-color: #c2f0c2 !important;
}

.output-table-days-left {
  font-size: 18px !important;
  color: white;
  border: 1px solid #00b2e2;
  border-radius: 50%;
  text-align: center;
  background-color: #00b2e2;
}

.output-table-creative-name-text-input {
  width: 350px !important;
}

.output-table-deadline-keyboard-picker {
  width: 120px;
}

.output-table-platform-dropdown {
  width: 190px !important;
  font-size: 14px !important;
}

.output-table-publisher-dropdown {
  width: 190px !important;
  font-size: 14px !important;
}

/* .output-select-chip {
  margin: 1px !important;
}

.output-select-chips {
  display: flex !important;
  flex-wrap: wrap !important;
} */

.output-select-chip {
  margin: 1px !important;
}

.output-select-chips {
  display: flex !important;
  flex-wrap: wrap !important;
  font-size: 50px !important;
}

.output-select-chips .MuiChip-root {
  height: 24px !important;
}

.output-dropdown svg.MuiSvgIcon-root.MuiSelect-icon.Mui-disabled {
  display: none !important;
}
.active-items-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.active-items-modal-title {
  display: flex;
  justify-content: center;
  height: 38px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.active-items-modal-table {
  min-width: auto;
  min-height: auto;
  background-color: whitesmoke;
}
.active-items-modal-text {
  display: flex;
  align-items: center;
}
.active-items-modal-button-panel {
  display: flex;
  justify-content: center;
  float: bottom;
}
.active-items-modal-body {
  min-width: 600px;
  min-height: auto;
  border-radius: 5px;
  background-color: whitesmoke;
}

.clickable {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.title-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.table-red {
  border: 3px solid red !important;
}

.table-green {
  border: 3px solid #5be65b !important;
}

.output-header-container {
  background-color: var(--secondary-color) !important;
  color: var(--text-color) !important;
  border-style: solid !important;
  border-width: 0px 0px 2px 0px !important;
  border-color: var(--secondary-color) !important;
  // height: 30px;
  svg, path{
    fill: var(--text-color)!important;
    stroke: var(--text-color) !important;
  }
}
