.budget-accordion-header-root {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.budget-accordion-header-table {
  .column1 {
    width: 9% !important;
  }

  .column2 {
    width: 59% !important;
  }

  .column3 {
    width: 12% !important;
  }

  .column4 {
    width: 12% !important;
  }

  .column5 {
    width: 8% !important;
  }

  .no-border-cell {
    border-bottom: none;
  }

  .no-padding-cell {
    padding: 0px 16px;
  }

  .no-padding-cell-all {
    padding: 0px 0px;
  }

  .no-background-cell {
    background: none;
  }

  .close-project-button {
    font-size: 10px !important;
    // padding: 8px;
    text-transform: capitalize !important;
  }
}

.budget-accordion-footer-table {
  margin-top: 10px;

  .column1 {
    width: 66.5%;
    text-align: end;
    font-size: 1.3rem;
  }

  .column2 {
    width: 10%;
  }

  .column3 {
    width: 10%;
  }

  .column4 {
    width: 10%;
  }

  .column4 {
    width: 3.5%;
  }

  .no-border-cell {
    border-bottom: none;
  }

  .no-padding-cell {
    padding: 0px 16px;
  }

  .no-background-cell {
    background: none;
  }
}

.budget-accordion-header-job-container {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
}

.budget-accordion-header-job-number-container {
  border: 1px solid #2d54e3;
  background-color: #E6EBFF;
  color: #2D54E3;
  border-radius: 5px;
  padding: 4px 10px;
  cursor: pointer;
}

.budget-accordion-header-job-number-container-closed {
  border: 1px solid #DADADA;
  background-color: #F6F6F6;
  color: #000000;
  border-radius: 5px;
  padding: 6px 8px;
  cursor: pointer;
}

.budget-accordion-header-job-name-container {
  font-size: 15px !important;
  line-height: 26px !important;
  letter-spacing: 0.17px !important;
  text-align: left !important;
}

.budget-accordion-header-total-container {
  color: #000000;
  border-radius: 5px;
  width: 50px;
  text-align: left;
  padding: 0px 2px 4px 0px;
}

.budget-accordion-header-total-container-empty {
  color: #000000;
  border-radius: 5px;
  width: 50px;
  text-align: center;
  padding: 0px 4px;
}

// .budget-accordion-header-total-container {
//   border: 1px solid #d4f6e2;
//   background-color: #d4f6e2;
//   color: #299965;
//   border-radius: 5px;
//   min-width: 70px;
//   text-align: center;
//   padding: 4px 10px;
// }

// .budget-accordion-header-total-container-empty {
//   border: 0px solid #DADADA;
//   background-color: #F6F6F6;
//   color: #000000;
//   border-radius: 5px;
//   min-width: 70px;
//   text-align: center;
//   padding: 4px 10px;
// }

.budget-accordion-header-total-column-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 70px;
}

.close-project-button-header {
  max-width: 100px !important;
  min-width: 100px !important;
}

.remaining-header {
  max-width: 50px !important;
  min-width: 50px !important;
}

.budget-item-details-root {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #D9D9D9;
  background-color: #F8F8F8;
  border-radius: 8px;
  padding: 20px 15px;
}

.budget-item-details-budget-name {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.17px;

}

.budget-item-details-table-root {
  border-collapse: separate !important;
  border-spacing: 4px !important;
  width: 100% !important;
  table-layout: fixed !important;

  td {
    border: 1px solid transparent;
    background: #fff;
  }

  .column1 {
    width: 40%;
  }

  .column2 {
    width: 20%;
  }

  .column3 {
    width: 10%;
  }

  .column4 {
    width: 3%;
  }

  .column5 {
    width: 10%;
  }

  .no-border-cell {
    border-bottom: none;
  }

  .no-padding-cell {
    padding: 0px 16px;
  }

  .no-background-cell {
    background: none;
  }
}

.text-green {
  color: #299965;
}

.close-and-transfer-dialog-close-icon-container {
  display: flex;
  justify-content: end;
  padding: 10px;
}

.close-and-transfer-dialog-close-icon .MuiSvgIcon-root {
  color: black;
}

.select-section-action-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chip,
.chip-short {
  width: 120px;
  max-height: 25px;
  padding: 6px 8px 6px 8px;
  border-radius: 100px;
  gap: 8px;
  font-size: 9px;
  text-align: center;
  vertical-align: middle;
  margin: 0px 2px;
  letter-spacing: 0.6px;
}

.chip-short {
  width: 65px;
}

.green-chip {
  color: #299965;
  background-color: #CDF9E5;
}

.red-chip {
  color: #D10000;
  background-color: #FFECEC;
}

.orange-chip {
  color: #EA8D38;
  background-color: #FFEEDE;
}

.light-blue-chip {
  color: #2e4352;
  background-color: #BDE1FB;
}

.NO_MEDIA_SPEND-chip {
  color: #6a7f8d;
  background-color: #ececec;
}

.INVOICE_SENT-chip {
  color: #299965;
  background-color: #CDF9E5;
}
.INVOICE_SUBMITTED-chip {
  color: #EA8D38;
  background-color: #FFEEDE;
}
.INVOICE_NOT_SUBMITTED-chip {
  color: #D10000;
  background-color: #FFECEC;
}


.add-budget-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.add-budget-popup-input-container {
  padding: 5px;

  .upload-client-header {
    font-family: "Roboto" !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.15px !important;
    padding-bottom: 5px !important;
  }

  .filepond--label-action {
    text-decoration: none !important;
  }

  .filepond--drop-label {
    height: 240px;
    background-color: #ffffff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px dashed rgba(0, 0, 0, 0.12);
    text-decoration: unset;
  }

  .drag-drop-mainline {
    color: rgba(0, 0, 0, 0.87);
  }

  .drag-drop-subline {
    color: rgba(0, 0, 0, 0.30);
  }
}

.screenshot-upload-button {
  display: none;
}

.add-budget-title {
  font-size: 36px !important;
  line-height: 44.46px !important;
  letter-spacing: 0.25px !important;
}

.add-budget-description {
  font-size: 16px !important;
  line-height: 22.88px !important;
  letter-spacing: 0.17px !important;
}

.add-budget-button, .download-as-excel-button {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 600;
  line-height: 16.022px;
  letter-spacing: 0.334px;
  text-transform: capitalize !important;
  padding: 8px 10px !important;
  margin-right: 10px !important;
}

#budget-close-and-transfer-dialog .MuiInputLabel-filled.MuiInputLabel-shrink,
#add-budget-dialog .MuiInputLabel-filled.MuiInputLabel-shrink {
  color: #4259EB;
}

#budget-close-and-transfer-dialog .MuiFilledInput-root,
#add-budget-dialog .MuiFilledInput-root {
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#budget-close-and-transfer-dialog .MuiFilledInput-root:before,
#add-budget-dialog .MuiFilledInput-root:before {
  border-bottom: 0px !important;
}

#budget-close-and-transfer-dialog .MuiFilledInput-root:after,
#add-budget-dialog .MuiFilledInput-root:after {
  border-bottom: 0px !important;
}

#budget-close-and-transfer-dialog .MuiDialog-paper,
#add-budget-dialog .MuiDialog-paper {
  background-color: #F3F3F3;
}

#budget-close-and-transfer-dialog input,
#add-budget-dialog input {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}