.slide-container {
    width: 70%;
    margin: auto;
    /* min-height: '180px'; */
  }
  
  h3 {
    text-align: center;
  }
  
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 1000px;
    /* min-height: '180px'; */
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  
  .each-fade {
    display: flex;
  }
  
  .each-fade .image-container {
    width: 75%;
    overflow: hidden;
    min-height: '180px';
  }
  
  .each-fade .image-container img {
    width: 100%;
    min-height: '180px';
  }
  
  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }
  