.media-campaign-invoice-actions-container {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}
.media-campaign-invoice-details-container {
    display: flex;
    gap: 5px;
}
.media-campaign-invoice-label {
    font-weight: 600;
}
.media-campaign-invoice-label-value {
    color: #767676;
}
.media-campaign-invoice-divider {
    padding: 15px 0px;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
}