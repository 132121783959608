.person-notes-table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.person-notes-form-root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fafafa;
  padding: 20px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
}
.person-notes-form-rating-container {
  display: flex;
  justify-content: center;
}
.person-notes-form-comment-container {
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  gap: 10px;
}
.person-notes-form-add-new-container {
  display: flex;
  justify-content: flex-end;
}

.person-notes-table-root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fafafa;
  padding: 20px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
}

.person-notes-table-record-heading {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
}

.person-notes-table-no-records{
    display: flex;
    justify-content: center;
    align-items: center;
}