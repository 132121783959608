.brand-badge-root {
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  max-width: 170px;
}

.brand-badge-default {
  //font-family: "Mier A Bold", sans-serif;
  color: #fff;
  font-size: 8px !important;
  font-weight: 800 !important;
  letter-spacing: 2px !important;
  text-transform: uppercase;
}

.brand-badge-small {
  font-family: "Mier A Bold", sans-serif;
  color: #fff;
  font-size: 8px !important;
  //font-weight: 800 !important;
  letter-spacing: 2px !important;
  text-transform: uppercase;
}

@media (max-width: 1300px) {
  .brand-badge-default {
    font-size: 6px !important;
    letter-spacing: 0.5px !important;
  }
  
  .brand-badge-small {
    font-size: 6px !important;
    letter-spacing: 0.5px !important;
  }
}

//Magnesium
.brand-badge-hover-1 {
  border: 1px solid #ff9e41 !important;
  background-color: #ff9e41 !important;
}
//Socialites
.brand-badge-hover-2 {
  border: 1px solid #db00ff !important;
  background-color: #db00ff !important;
}

//The Social Club
.brand-badge-hover-3 {
  border: 1px solid #00B0C8 !important;
  background-color: #00B0C8 !important;
}
//FlyingTiger
.brand-badge-hover-4 {
  border: 1px solid #ff0027 !important;
  background-color: #ff0027 !important;
}
//PeopleInfluence
.brand-badge-hover-5 {
  border: 1px solid #0000ff !important;
  background-color: #0000ff !important;
}
//MediaX
.brand-badge-hover-6 {
  border: 1px solid #000000 !important;
  background-color: #000000 !important;
}
