.confirm-invoice-dialog-root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.confirm-invoice-dialog-review-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
