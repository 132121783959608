.deposit-table-body-cell {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.deposit-table-header-cell {
  font-family: Arial, Helvetica, sans-serif !important;
  height: 40px !important;
  background-color: white !important;
  color: black !important;
  font-weight: "bold" !important;
  border: 1px solid black !important;
}

.acknowledge-header-cell {
  width: 180px !important;
}

.deposit-table-row-overdue {
  background-color: #ffcccc !important;
}

.deposit-table-overdue-text {
  font-weight: bold !important;
  font-size: 1.2em !important;
  background-color: #ff8080 !important;
}

#deposit-table .MuiTableRow-hover:hover {
  background-color: #ade6e6 !important;
}
