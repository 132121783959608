.payables-table-root {
  display: grid;
  grid-template-columns: 1fr;
}

.payables-table-gap-container {
}

.payables-table-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 25px 0;
}

.payables-table-nav-header {
  display: flex;
  justify-content: space-between;
}

.payables-table-nav-header-btn {
  text-transform: none !important;
}

.payables-table-no-records {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.payables-table-records-container {
  width: 100%;
  .MuiTableCell-head {
    font-weight: 700 !important;
  }
}

.payables-table-transfer-budget-container{
  display: flex;
  justify-content: end;
}
.payables-table-allocated-budget-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}