.approval-request-form-content-container{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px;
}

.approval-request-form-footer-container{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.approval-request-form-inputs-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}