.pointer {
  cursor: pointer;
}

.headCell {
  background-color: white !important;
  color: black !important;
  font-weight: "bold" !important;
  border: 1px solid black !important;
}

.apply-filter {
    position: relative !important;
    padding-right: 40px !important;
}

.table-filter-icon.selected {
    border-top-color: red !important;
    box-shadow: inset 0 4px red !important;
}

.bodyCell {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.statusHeaderColumn {
  width: 230px !important;
}

.deleteHeaderColumn {
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
}

.projectNameHeaderColumn {
  width: 350px !important;
  max-width: 350px !important;
  min-width: 350px !important;
}

.dueDateHeaderColumn {
  width: 130px !important;
}

.typeHeaderColumn {
  width: 130px !important;
  max-width: 130px !important;
  min-width: 130px !important;
}

.clickable {
  color: blue;
  text-decoration: underline;
}

.totalAmountDue {
  font-size: 1rem !important;
  font-weight: "bold" !important;
}

.add-margin-left-right-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}