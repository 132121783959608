.client-people-of-influence-table-root-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding-bottom: 20px;
  }
  
  .client-people-of-influence-table-filter-container {
    .MuiFilledInput-root {
      background-color: #ffffff !important;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  
    .MuiFilledInput-root:before {
      border-bottom: 0px !important;
    }
  
    .MuiFilledInput-root:after {
      border-bottom: 0px !important;
    }
  }
  
  .client-people-of-influence-table-tbody-tr{
      cursor: pointer;
  }
  .client-people-of-influence-table-no-records{
    display: flex;
    justify-content: center;
  }

  .client-people-of-influence-table-tooltip-icon{
    display: grid;
    grid-template-columns: 1fr;
  }

  .client-people-of-influence-table-flex-container{
    display: flex;
    justify-content: center;
    gap: 10px;
  }