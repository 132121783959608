.checkbox-cell-width {
  padding: 0px 2px !important;
  margin: 0px 2px !important;
}

.send-schedule-text-editor-wrapper {
  background-color: white;
  border-left: 1px solid #f1f1f1 !important;
  border-right: 1px solid #f1f1f1 !important;
  border-bottom: 1px solid #f1f1f1 !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.send-schedule-text-editor {
  padding: 0px 15px 15px 15px;
}

.subject-container {
  flex-basis: 100% !important;
  height: 60 !important;
  display: flex !important;
  margin-top: 5px !important;
}

.send-button-container {
  display: flex !important;
  justify-content: flex-end !important;
}

.center-subject-item {
  align-self: center !important;
  margin-bottom: 3px !important;
  margin-left: 5px !important;
}

.schedule-checkbox {
  color: #4dfa59 !important;
}

.arial {
  font-family: Arial, Helvetica, sans-serif;
}

.preview-hr {
  border-top: 1px solid #cfcfcf;
}

.people-list-enable-preview-button {
  margin-left: 10px !important;
  color: #ed9c91 !important;
  width: 20px !important;
  height: 20px !important;
}

.rdw-image-wrapper {
  flex-basis: 10% !important;
  @media (max-width: 1280px) {
      flex-basis: 100% !important;
  }
}

.drop-email-image-here-something {
  background-color: aliceblue;
  margin-top: 2%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  border-color: rgb(238, 238, 238);
  border-width: 2px;
  border-style: dashed;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}

.drop-email-image-here-nothing {
  padding: 10%;
  background-color: aliceblue;
  margin-top: 2%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  border-color: rgb(238, 238, 238);
  border-width: 2px;
  border-style: dashed;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}

.insert-image-size-button-container {
  display: flex;
  justify-content: space-around;
  margin: 10px;
}
.center {
  display: flex;
  justify-content: center;
  flex-grow: 2;
}
