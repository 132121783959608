.project-table-sort-arrow {
  cursor: pointer;
}
#root > div > div > div:nth-child(2) > div > div > div > div > div > input {
  background-color: white;
}

.days-left-green {
  background-color: #c1f0c1;
  position: relative;
  border-radius: 50%;
  min-width: 2em;
  min-height: 2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px !important;
  margin-right: 5px !important;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.days-left-red {
  background-color: #ed9c91;
  position: relative;
  border-radius: 50%;
  min-width: 2em;
  min-height: 2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px !important;
  margin-right: 5px !important;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
