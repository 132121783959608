@import "Invoice";

$mainFontSize: 16px;
//Popular Phone Sizes
$iPhoneXR: 896px;
$SamsungGalaxyNote5: 853px;
$GooglePixel3: 824px;
$iPhoneX: 812px;
$SamsungGalaxyNote9: 740px;
$iPhone8: 667px;
$SamsungGalaxyS7: 640px;
$iPhone5: 568px;

h2 {
  margin-left: 5px;
  font-size: 1.1em;
}

.invoiceTitle {
  font-weight: bold;
  margin-top: 15px;
}

.columnMinWidth {
  min-width: 40px;;
}

.acceptButtonContainer {
  margin-top: 15px;
  text-align: center !important;
  width: 100vw;
}

.acceptButton {
  color: white !important;
  background-color: green !important;
  margin: 15px 0px 15px 0px !important;
}

.invoice {
  max-width: $invoiceMaxWidth;
  max-height: $invoiceMaxHeight;
  // min-height: $invoiceMinHeight;
  margin: ($rhythm * 2) auto;
  // border: 1px solid $tableBorderColor;
  // border-top: 1px solid $tableBorderColor;
  border-bottom: 1px solid $tableBorderColor;
  padding: 2 * $rhythm;
  // border-radius: $borderRadius;
  background: $bgColor;
  font-family: "Roboto", "Helvetica", Helvetica, Arial, sans-serif;
  overflow: auto;
  font-size: $mainFontSize;
  @media (max-height: $iPhoneXR) {
    margin: $rhythm auto;
    padding: $rhythm;
  }
}

.brand {
  margin-bottom: $rhythm * 2;
  .logo {
    display: inline-block;
    // border-radius: $borderRadius;
  }
}

.termsConditionsTitle {
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 0.8em !important;
  }

  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    font-size: 1em !important;
  }
}

.termsConditionsBody {
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 0.7em !important;
  }

  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    font-size: 0.9em !important;
  }
}

.invoiceTable {
  width: 100%;
  border-bottom: 1px solid $tableBorderColor;
  margin-top: 15px;
  margin-bottom: 5px;
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 0.6em;
    // min-width: calc(100vw - 50px);
    // max-width: calc(100vw - 50px);
  }

  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    font-size: 0.8em;
    min-width: calc(100vw - 50px);
  }
}

.invoiceFooterTable {
  width: 100%;
  border-bottom: 1px solid $tableBorderColor;
  margin-bottom: 15px;

  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 0.7em;
    min-width: 280px;
  }

  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    font-size: 0.8em;
    min-width: calc(100vw - 50px);
  }
}
.invoiceTableHeader {
  border-bottom: 1px solid $tableBorderColor;
  border-left: none;
  border-right: none;
  // background-color: rgba($color: #d9e7ff, $alpha: 0.5);
  background-color: #cacaca;
}
.invoiceTableRow {
  border-bottom: 1px solid $tableBorderColor;
}
td {
  padding: 15px;
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    padding: 5px;
  }

}
.invoiceFrom {
  margin-bottom: 15px;
  margin-top: 5px;
}

.addresses {
  display: flex;
  flex-flow: inherit;
  justify-content: space-between;
  text-align: left;
  .from,
  .to,
  .fromTo {
    padding: $rhythm;
    // border-radius: $borderRadius;
    width: 100%;
    max-width: $invoiceMaxWidth / 3;

    @media (max-height: $iPhoneXR) and (orientation: portrait) {
      padding: 5px;
      font-size: 0.5em;
    }
  
    @media (max-width: $iPhoneXR) and (orientation: landscape) {
      padding: 10px;
      font-size: 0.8em;
    }
  }
  .from {
    display: block;
    font-style: normal;
    margin-right: $rhythm / 3;
    // background: #efefef;
    // border: 1px solid $tableBorderColor;
    @media (max-height: $iPhoneXR) {
      min-width: auto;
    }
  }
  .fromTo {
    display: block;
    font-style: normal;
    margin-right: $rhythm / 3;
    // background: #efefef;
    // border: 1px solid $tableBorderColor;

    @media (max-height: $iPhoneXR) and (orientation: portrait) {
      min-width: auto;
      margin-left: 0px;
      margin-right: 5px;
    }
  
    @media (max-width: $iPhoneXR) and (orientation: landscape) {
      min-width: auto;
      margin-left: 5px;
      margin-right: 10px;
    }
  }
  .to {
    display: block;
    padding: 0;
    .value {
      justify-self: end;
      width: max-content;
    }
  }
  .address {
    display: block;
  }
}

.valueTable {
  margin: 0 0 0 auto;
  // border: 1px solid $tableBorderColor;
  // border-radius: $borderRadius;
  overflow: hidden;
  width: 100%;
  min-width: $invoiceMaxWidth / 3;
  max-width: $invoiceMaxWidth / 3;
  & > .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // border-bottom: 1px solid $tableBorderColor;
    &:last-of-type {
      border-bottom: none;
    }
    & > div {
      padding: ($rhythm/2);
    }
    & > .label {
      @extend %flex-center;
      justify-content: flex-start;
      font-weight: bold;
      width: max-content;
    }
  }
  @media (max-height: $iPhoneXR) {
    min-width: 70px;
  }
}

.totalContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @media (max-height: $iPhoneXR) and (orientation: portrait) {
    font-size: 0.7em;
    min-width: 280px;
    padding: 0;
  }

  @media (max-width: $iPhoneXR) and (orientation: landscape) {
    font-size: 0.8em;
    min-width: calc(100vw - 45px);
  }
}
form .valueTable {
  @media (max-height: $iPhoneXR) {
    max-width: 180px;
    min-width: 180px;
    align-items: flex-end;
  }
}

.pay {
  display: flex;
  justify-content: flex-end;
  padding: ($rhythm * 1) 0 ($rhythm) $rhythm;
}

button.payNow {
  display: block;
  padding: ($rhythm) ($rhythm * 2);
  background: lightyellow;
  // border-radius: $borderRadius;
  border: 1px solid #444;
}

.footer {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  .comments {
    padding: $rhythm;
    // border-radius: $borderRadius;
    h4,
    h5 {
      margin-top: 0;
      margin-bottom: $rhythm / 2;
    }
  }
  .comments {
    text-align: left;
    background: var(--background-color);
  }
  .closing {
    padding-top: $rhythm;
  }
}

.lineItem {
  display: grid;
  grid-template-columns: 1fr repeat(2, 5fr) 2fr repeat(2, 3fr) 2fr;

  & > div {
    @extend %flex-center;
    padding: ($rhythm/2);
    border-left: 1px solid $tableBorderColor;
    width: 100%;
    line-height: $rhythm;
    min-width: $rhythm;
    &:first-of-type {
      padding: ($rhythm/2) 0;
      border-left: none;
    }
    &:last-of-type {
      padding: ($rhythm/2) 0;
    }
    &:first-of-type,
    &:nth-child(4),
    &:last-of-type {
      text-align: center;
    }
  }
}

.deleteItem {
  @extend %flex-center;
  color: $removeColor;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
}

input,
input[type="number"] {
  padding: $rhythm / 4;
  // border-radius: $borderRadius;
  min-width: $rhythm * 2;
  width: 100%;
}
input[type="number"] {
  text-align: center;
}
