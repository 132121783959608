.schedule-item-contract-status-badge-root{
    max-width: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
}

.schedule-item-contract-status-badge-status{
    color: #000;
    font-size: 8px;
    font-weight: 700;
    text-transform: lowercase;
}