.campaigns-table-root-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding-bottom: 20px;
}

.campaigns-table-filter-container {
  .MuiFilledInput-root {
    background-color: #ffffff !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .MuiFilledInput-root:before {
    border-bottom: 0px !important;
  }

  .MuiFilledInput-root:after {
    border-bottom: 0px !important;
  }
}

.campaigns-table-tbody-tr{
    cursor: pointer;
}