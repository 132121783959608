.reimbursment-head-cell {
  background-color: white !important;
  color: black !important;
  font-weight: "bold" !important;
  border: 1px solid black !important;
  width: auto !important;
}

.reimbursment-head-cell-gst {
  width: 80px !important;
}

.reimbursment-head-cell-account-code {
  width: 90px !important;
}

.reimbursment-head-cell-camistry-card {
  width: 90px !important;
}

.reimbursment-head-cell-date {
  width: 110px !important;
}

.reimbursment-head-cell-driveId {
  width: 90px !important;
}

.reimbursment-head-cell-amount {
  width: 95px !important;
}

.reimbursment-head-cell-status {
  width: 130px !important;
}

.reimbursment-head-cell-type {
  /* width: 90px !important; */
}

.reimbursment-head-cell-remaining {
  /* width: 90px !important; */
}
.reimbursment-head-cell-actions {
  width: 90px !important;
  border-bottom-width: 0px !important;
}

.reimbursment-body-cell {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.reimbursment-text-field {
  padding: 0px !important;
}

.receipt-image {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  width: 100% !important;
}

.reimbursment-table-row-over-budget {
  background-color: #ffcccc !important;
}

.reimbursment-table-remaining-over-budget {
  font-weight: bold !important;
  font-size: 1.2em !important;
  background-color: #ff8080 !important;
}

#reimbursment-table .MuiTableRow-hover:hover {
  background-color: #ade6e6;
}

#receipt-details-reimburse-button-container .MuiGrid-item {
  display: flex !important;
  justify-content: center !important;
}

