.contact-center-container {
    padding: 20px;
}

.contact-table {
    .contact-center-person-row {
        background-color: #ffffff;
    }

    .contact-center-person-row:hover {
        background-color: #ddd;
        cursor: pointer;
    }
}