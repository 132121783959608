.expenses-table-records-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.expenses-table-no-records {
  display: flex;
  justify-content: center;
}

.expense-table-root-container {
  width: 100%;
  .MuiTableCell-head {
    font-weight: 700 !important;
  }
}

.expense-table-project-name-column {
  padding-left: 21px !important;

}
.expense-table-budget-item-column {
  padding-left: 30px !important;
}
.expense-table-schedule-item-column {
  padding-left: 40px !important;
}

.expense-table-schedule-item-column-wrapper{
    display: flex;
    gap: 15px;
}
.expense-table-project-links-container{
  display: flex;
  gap: 9px;
  align-items: end;
}

.expense-table-project-name-link{
  text-decoration: underline;
  color: #0366d6;
  cursor: pointer;
}