.catering-table-cell {
  padding: 6px 2px 6px 2px !important;
  margin: 6px 2px 6px 2px !important;
}

.catering-table-meal-dropdown {
  min-width: 150px !important;
}

.catering-popup-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden !important;
  position: relative;
}

.catering-tally {
  font-size: 18px !important;
  margin: 0px 10px !important;
}

.catering-meals-left {
  font-size: 18px !important;
  margin: 0px 10px !important;
}

.catering-divider {
  font-size: 18px !important;
}

.output-catering-table-red {
  border: 3px solid red !important;
}

.shake {
  animation: tableShake 0.5s;
  animation-iteration-count: 1;
}

.output-catering-table-green {
  border: 3px solid #5be65b !important;
}

.output-tab-message {
  font-size: 20px;
}

.output-tab-disabled {
  background-color: rgb(114, 112, 112) !important;
}

.output-delivery-section {
  display: flex;
  align-items: center;
}

.catering-notes {
  color:  rgb(160, 158, 158) !important;
}

@keyframes tableShake {
  0% {
    transform: translate(0.5px, 0.5px) rotate(0.5deg);
  }
  10% {
    transform: translate(-0.5px, -1px) rotate(-0.5deg);
  }
  20% {
    transform: translate(-1.5px, 0.5px) rotate(0.5deg);
  }
  30% {
    transform: translate(1.5px, 1px) rotate(0deg);
  }
  40% {
    transform: translate(0.5px, -0.5px) rotate(0.5deg);
  }
  50% {
    transform: translate(-0.5px, 1px) rotate(-0.5deg);
  }
  60% {
    transform: translate(-1.5px, 0.5px) rotate(0.5deg);
  }
  70% {
    transform: translate(1.5px, 0.5px) rotate(-0.5deg);
  }
  80% {
    transform: translate(-0.5px, -0.5px) rotate(0.5deg);
  }
  90% {
    transform: translate(0.5px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(0.5px, -1px) rotate(-0.5deg);
  }
}
