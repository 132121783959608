.pre-post-production-table-container {
  padding: 5px 0px;
  background-color: white;
  border-radius: 8px;
}

.pre-post-production-add-new-container {
  background-color: white;
  border-radius: 8px;
}
.pre-post-production-form-table-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.pre-post-production-no-records-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pre-post-production-table-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
