.client-details {
    grid-area: header;
    width: auto;
}

.use-budget-checkbox {
    grid-area: checkbox;
}

.total {
    grid-area: total;
}

.expected {
    grid-area: expected;
}

.received {
    grid-area: received;
}

.underover {
    grid-area: underover;
}

.unmatched {
    grid-area: unmatched;
}

.recon-table-row-card-container {
    padding: 2px 0px;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "header header checkbox total"
    "expected received underover unmatched";
}