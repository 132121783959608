.master-status-item-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
.master-status-item-header {
  display: grid;
  grid-template-columns: 1fr;
}
.master-status-root-container {
  width: 100%;
  .MuiTableCell-head {
    font-weight: 700 !important;
  }
}
.master-status-table-icon-column {
    width: 20px
}