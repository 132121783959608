.thead-custom-table-head {
  th {
    background-color: white !important;
    color: black !important;
    font-size: 0.9rem;
    border-top: unset !important;
    font-weight: 700;
    padding: 10px 0px 10px 10px;
  }
}
.thead-sorting-header {
  width: 100%;
  > svg {
    margin-left: 0;
    margin-right: 0;
  }
}

.MuiTableSortLabel-icon {
  color: black !important;
}

.MuiTableSortLabel-root {
  color: black !important;
}

.MuiTableSortLabel-active {
  color: black !important;
}
