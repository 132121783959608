.media-invoice-settings-root{
    display: flex;
    flex: 1; 
    gap: 20px;
    margin-top: 50px;
}
.media-invoice-settings-grid-wrapper{
    padding: 0 30px;
    display: flex;
    flex: 1;
}
.media-invoice-settings-grid-container{
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
}