.outreach-dialog-body-container{
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    @media(max-width: 1200px){
        grid-template-columns: 1fr;
    }
}

.outreach-dialog-footer-container{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}