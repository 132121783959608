.custom-payable-table-row-details-root {
  background-color: #e4e4e4;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.custom-payable-table-row-details-fields-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  background: white;
  padding: 10px;
}

.custom-payable-table-row-details-fields-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.custom-payable-table-row-details-file-container {
  width: 100%;
  height: 80vh;
}

.custom-payable-table-row-details-file-container-pdf-iframe {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  max-width: 100%;
  max-height: 100%;
}

.custom-payable-table-row-details-remove-container {
  display: flex;
  justify-content: end;
  align-items: center;
  .MuiIconButton-root{
    padding: 0px !important
  }
  svg {
    font-size: 1rem !important;
    cursor: pointer;
  }
}
.custom-payable-table-row-details-remove-txt {
  text-decoration: underline;
  font-style: italic;
  font-size: 1rem !important;
  cursor: pointer;
}
