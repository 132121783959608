.influencer-outreach-details-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  padding: 15px;
}

.influencer-outreach-details-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.influencer-outreach-details-label {
  display: grid;
  grid-template-columns: 1fr;
  span {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 700;
  }
}

.influencer-outreach-details-name-container {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  align-items: center;
}

.influencer-outreach-details-name {
  display: grid;
  grid-template-columns: 1fr;
}

.influencer-outreach-details-name-link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.influencer-outreach-details-avatar-image {
  height: 40px;
  width: 40px;
}

.influencer-outreach-details-divider {
  border-top: 1px solid #c4c4c4;
}

.influencer-outreach-details-body {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
.influencer-outreach-details-footer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  padding: 10px;
  background: var(--background-color);
  border-radius: 4px;
}
.influencer-outreach-details-2columns-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.influencer-outreach-details-container-link {
  text-decoration: underline;
  cursor: pointer;
}
.influencer-outreach-details-autochase-container{
  display: flex;
  justify-content: flex-end;
  margin-right: 67px;
}