.rwt-table-root-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 100%;
}
.rwt-table-no-records {
  display: flex;
  justify-content: center;
}
.rwt-table-link{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.rwt-report-table-container{
    display: flex;
    gap: 20px;
}