.right-aligned-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 155%;
}

.paper-match-left-popup {
    background-color: #ffffff !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2) !important;
    padding: 16px 32px 24px !important;
    min-height: 550px !important;
    width: 25vw !important;
    overflow: hidden !important;
}

.header-talent-agency {
    background: black !important;
}

.header-action-active {
    color: white !important;
}

.header-action-hidden {
    display: none !important;
}