.media-deliveries-dialog-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.close-button-container {
    display: flex;
    justify-content: flex-end;
}

.media-deliveries-dialog-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.5fr;
}

.media-delivery-table-head {
    text-align: left;
}

.media-deliveries-table-container {
    padding-bottom: 10px;
}

#adstream-deliverables-dialog {
    .title {
        font-size: 30px !important;
        line-height: 44.46px !important;
        letter-spacing: 0.25px !important;
        margin-bottom: 10px !important;
    }

    .text-center {
        text-align: center;
    }

    .badge {
        display: inline-block;
        padding: 0.7em;
        font-size: 70%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .2rem;
    }

    .badge-warning {
        text-transform: uppercase;
        height: 20px;
        line-height: 21px;
        color: white;
        background-color: #f4777e;
        padding: 0px 1rem 0px 1rem;
        height: auto;
    }

    .badge-success {
        text-transform: uppercase;
        height: 20px;
        line-height: 21px;
        color: white;
        background-color: #1bc943;
        padding: 0px 1rem 0px 1rem;
        height: auto;
    }

    .MuiPaper-root {
        background-color: #F3F3F3 !important;
    }

    .MuiInputLabel-filled.MuiInputLabel-shrink {
        color: #4259EB;
    }

    .MuiFilledInput-root {
        background-color: #ffffff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .MuiFilledInput-root:before {
        border-bottom: 0px !important;
    }

    .MuiFilledInput-root:after {
        border-bottom: 0px !important;
    }

    .MuiDialog-paper {
        background-color: #F3F3F3;
    }

    input {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    }

    td {
        padding: unset;
    }
}