.influencer-basic-details-name-column {
  display: flex;
  gap: 15px;
  // max-width: 130px;
  min-height: 93px;
}

.influencer-basic-details-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.influencer-basic-details-flex-container {
  display: flex;
  gap: 5px;
}
.influencer-basic-details-grid-container {
  // display: grid;
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
  span {
    font-size: 0.65rem !important;
  }
}

.influencer-basic-details-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.influencer-basic-details-avatar-image {
  height: 70px !important;
  width: 70px !important;
}

.influencer-basic-details-profiles-container {
  display: flex;
  gap: 10px;
  svg {
    width: 18px;
  }
}

.influencer-basic-details-flex-space-between {
  display: flex;
  justify-content: space-between;
}