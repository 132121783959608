.month-range-picker-container.rmdp-wrapper,
.rmdp-container .month-range-picker-container {
//   background-color: #F3F3F3;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

.month-range-picker-container.rmdp-wrapper,
.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
    background-color: #ffffff;
    color: black;
}

.month-range-picker-container{
    .rmdp-range {
        background-color: #6E80ED !important;
        box-shadow: unset !important;
        color: #000000 !important;
    }
    .rmdp-month-picker{
        background-color: #F0F0F0 !important;
    }
    .rmdp-arrow {
        border: solid #b1b1b1 !important;
        border-width: 0 2px 2px 0 !important;
    }
    .rmdp-arrow-container:hover {
        background-color: transparent !important;
    }
    // .rmdp-arrow:hover {
    //     border: solid #ffffff !important;
    // }
    .only.rmdp-month-picker, .only.rmdp-year-picker{
        height: 143px !important; 
    }
    .rmdp-day.rmdp-today span{
        background-color: unset;
    }

    .rmdp-day.rmdp-today span:hover{
        background-color: #ffffff;
    }
        
}



.month-range-picker-container.rmdp-wrapper,
.rmdp-day span{
    font-family: 'Roboto' ;
    font-size: 11px !important;
    color: #ffffff;
}
.month-range-picker-container.rmdp-wrapper, 
.rmdp-day:not(.rmdp-range) span 
{
    background-color: #ffffff;
    color:#000000;
}
.month-range-picker-container.rmdp-wrapper,
.rmdp-day, .rmdp-week-day{
    height: 26px !important;
}
.month-range-picker-container.rmdp-wrapper,
.rmdp-ym{
    height: 15% !important;
    padding: 6px;
}
