.project-outputs-table-th {
  width: 250px;
  @media (min-width: 1781px) and (max-width: 1980px) {
    width: 22%;
  }
  @media (max-width: 1780px) and (orientation: landscape) {
    width: 21.5%;
  }
}

.project-outputs-table-th-creativeName {
  width: 23%;
  @media (min-width: 2300px) and (max-width: 2656px) {
    width: 24%;
  }
  @media (min-width: 2100px) and (max-width: 2299px) {
    width: 26%;
  }
}

#overdue-outputs-popup-dialog .MuiDialog-paperWidthSm {
  max-width: 80% !important;
}

.overdue-outputs-table {
  table-layout: fixed !important;
}

.overdue-outputs-table th {
  background-color: #3f51b5;
  color: white;
}

.overdue-outputs-table tr:nth-child(odd) {
  background-color: #d4ebf2;
}

#alert-overdue-outputs-title {
  text-align: center;
}

#alert-overdue-outputs-subtitle {
  text-align: center;
}
