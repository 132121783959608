.budget-transfer-paper {
    background: #F3F3F3;
    width: 80%;
    padding: 50px;
}

.budget-transfer-field-container {
    padding-top: 10px;
}

.title {
    width: 583px;
    height: 24px;
    top: 311px;
    left: 463px;
}

input[type=number]#amount-to-transfer {
    text-align: left;
}

.error-text {
    color: red !important;
}

.transfer-sucess {
    color: #299965 !important;
    font-size: 58px !important;
    font-weight: 800 !important;
    line-height: 71px !important;
    letter-spacing: -0.05678078904747963px !important;
    text-align: center !important;
    margin-bottom: 20px !important;
}

.budget-transfer-edit-container {
    text-align: center !important;
    padding-top: 30px !important;
}

.budget-transfer-success-container {
    text-align: center !important;
    background-color: white !important;
    padding: 30px !important;
}

.light-text {
    color: #79747E;
}

.budget-transfer-dialog-close-icon-container {
    display: flex;
    justify-content: end;
    position: relative;
    top: -40px;
    right: -40px;
}

.budget-transfer-dialog-close-icon .MuiSvgIcon-root {
    color: black;
}

#budget-transfer-dialog .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #4259EB;
}

#budget-transfer-dialog .MuiFilledInput-root {
    background-color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

#budget-transfer-dialog .MuiFilledInput-root:before {
    border-bottom: 0px !important;
}

#budget-transfer-dialog .MuiFilledInput-root:after {
    border-bottom: 0px !important;
}

#budget-transfer-dialog .MuiDialog-paper {
    background-color: #F3F3F3;
}

#budget-transfer-dialog input {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}