.person-general-tab-root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.person-general-tab-header-wrapper {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}
.person-general-form-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.person-general-form-grid-columns-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.person-general-form-switch-labels-container {
  display: flex;
  gap: 5px;
}
