.purchase-order-table-root-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding-bottom: 20px;
}

.purchase-order-table-filter-container {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  .MuiFilledInput-root {
    background-color: #ffffff !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .MuiFilledInput-root:before {
    border-bottom: 0px !important;
  }

  .MuiFilledInput-root:after {
    border-bottom: 0px !important;
  }
}

.purchase-order-table-no-records {
  display: flex;
  justify-content: center;
}
.purchase-order-table-status-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.purchase-order-table-status-sent-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.purchase-order-table-sent-label{
  font-size: 8px !important;
  color: green;
}
