.reconciliation-centre-root {

  .MuiCheckbox-root {
    transform: scale(1.3);
    color: black !important;
  }

  .Mui-checked {
    color: black;
  }
}

.reconciliation-centre-main-container {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  gap: 10px;
  position: relative;
}

.reconciliation-centre-bills-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 30px;
  overflow-y: auto;
  align-items: flex-start;

  @media (max-width: 1400px) {
    padding: 10px;
  }
}

.reconciliation-centre-records-wrapper {
  background-color: #f6f6f6;
  padding: 25px;
  position: sticky;
  top: 40px;
  height: 90vh;
  overflow-y: auto;
}

.reconciliation-centre-title {
  font-size: 20px !important;
  line-height: 25.74px !important;
  letter-spacing: 0.17px !important;
  text-align: left !important;
}

.reconciliation-centre-bills-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.reconciliation-centre-skeleton-container {
  display: grid;
  grid-template-columns: 1fr;
}

.reconciliation-centre-no-records-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1;
  justify-content: center;
}

.reconciliation-centre-payables-wrapper {
  display: grid;
  gap: 20px;
}

.reconciliation-centre-cards-container {
  display: flex;
  gap: 20px;

  @media (max-width: 1400px) {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.reconciliation-centre-cards-props {
  min-width: 170px;
  display: grid;
  gap: 5px;
  padding: 10px;
}

.reconciliation-centre-payables-list-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}

.reconciliation-centre-payable-row-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 10px;
  border-radius: 8px;
  // background-color: #e6eaff;
}

.reconciliation-centre-payable-row-header {
  display: grid;
  grid-template-columns: 0.9fr 0.1fr;
  cursor: pointer;
}

.reconciliation-centre-payable-row-media-approved-by-media-team {
  background-color: #E6EAFF;

  .reconciliation-payable-row-record-type-container {
    background-color: #EEF1FF;
    color: #0022ff;

    svg {
      fill: #0022ff;
    }
  }
}

.reconciliation-centre-payable-row-receipt-approved-for-payment {
  background-color: #67e1a8;
}

.reconciliation-centre-payable-row-media-1-type {
  background-color: #E6EAFF;

  .reconciliation-payable-row-record-type-container {
    background-color: #EEF1FF;
    color: #4259EB;

    svg {
      fill: #4259EB;
    }
  }
}

.reconciliation-centre-payable-row-media-2-type {
  background-color: #F3E6FF;

  .reconciliation-payable-row-record-type-container {
    background-color: #F7EFFF;
    color: #AB42EB;

    svg {
      fill: #AB42EB;
    }
  }
}

.reconciliation-centre-payable-row-media-3-type {
  background-color: #E6F6FF;

  .reconciliation-payable-row-record-type-container {
    background-color: #F5FBFF;
    color: #42C2EB;

    svg {
      fill: #42C2EB;
    }
  }
}

.reconciliation-centre-payable-row-media-4-type {
  background-color: #FFF5E6;

  .reconciliation-payable-row-record-type-container {
    background-color: #FFFCF7;
    color: #EBBC42;

    svg {
      fill: #EBBC42;
    }
  }
}

.reconciliation-centre-payable-row-RECEIPT-type {
  background-color: #c4f6d8;
}

.reconciliation-centre-payable-row-PO-type {
  background-color: #f6f4e1;
}

.reconciliation-centre-payable-row-container {
  display: grid;
  grid-template-columns: 0.2fr 1fr 0.8fr;
  gap: 10px;
  align-items: center;
}

.reconciliation-total-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.reconciliation-align-button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  @media (max-width: 1400px) {
    display: grid;
    justify-content: unset;
    grid-template-columns: 1fr 1fr;
  }
}

.reconciliation-align-button-container-start {
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  @media (max-width: 1400px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .reconciliation-reject-receipt-button {
      grid-column: span 2;
    }
  }

  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.gst-rate-code-checkbox {
  display: flex;
  justify-content: center;
  width: 100%;
}

.reconciliation-align-note-container {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;

  .media-reconciliation-note-textfield {
    grid-column: span 3;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;

    .media-reconciliation-note-textfield {
      grid-column: span 2;
    }

    .gst-rate-code-checkbox {
      grid-column: span 2;
    }
  }
}

.reconciliation-save-button-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  @media (max-width: 1400px) {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.reconciliation-payable-row-record-type-container {
  display: flex;
  align-items: center;
  padding: 8px 20px 8px 20px;
  border-radius: 3px;
  gap: 5px;
  min-width: 80px;

  @media (max-width: 1000px) {
    min-width: 40px;
    font-size: small;
    padding: 4px 10px 4px 10px;
    display: unset;
  }
}

.reconciliation-payable-row-record-type-MEDIA-default {
  background-color: #eef1ff !important;
  color: #4259eb !important;

  hr {
    background-color: #4259eb !important;
  }

  svg {
    fill: #4259eb;
  }
}

.reconciliation-payable-row-record-type-RECEIPT {
  background-color: #d2fee4 !important;
  color: #39b66b !important;

  hr {
    background-color: #39b66b !important;
  }

  svg {
    fill: #39b66b;
  }
}

.reconciliation-payable-row-record-type-PO {
  background-color: #fffdee;
  color: #d4c32f;

  hr {
    background-color: #d4c32f !important;
  }

  svg {
    fill: #d4c32f;
  }
}

.reconciliation-payable-row-record-name-container {
  display: grid;
}

.reconciliation-payable-row-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reconciliation-payable-row-body-container {
  display: grid;
  gap: 20px;

  @media (max-width: 1000px) {
    max-width: 1fr !important;
  }
}

.reconciliation-payable-row-iframe-container {
  width: 100%;
  height: 80vh;

  @media (max-width: 1000px) {
    height: 60vh;
  }
}

.reconciliation-payable-row-pdf-iframe {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  max-width: 100%;
  max-height: 100%;
}

.payable-media-form-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.payable-media-form-columns-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.payable-media-receipt-form-container {
  .padding-5 {
    padding: 2px 0px;
  }

  .gst-section {
    display: flex;
    justify-content: center;
  }

  .total {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
  }

  .description {
    grid-column: span 2;
  }

  @media(min-width: 1400px) {
    display: grid;
    gap: 5px;

    .project {
      grid-column: span 2;
    }

    .total {
      grid-column: span 2;
    }

    .padding-5 {
      padding: unset;
    }
  }
}

.reconcile-table-cell-root {
  background-color: white;
  border-bottom: 0px !important;
  padding: 5px !important;
}

.reconcile-table-head-cell-root {
  border-bottom: 0px !important;
  padding: 0px !important;
  font-family: "Roboto Bold" !important;

  span {
    font-weight: 800 !important;
  }
}

.reconcile-centre-table-root {
  border-spacing: 5px 5px !important;
  border-collapse: separate !important;
}

.reconcile-table-single-checkbox-container {
  display: flex;
  justify-content: center;
}

.reconcile-action-button-root {
  display: flex;
  justify-content: flex-end;
}

.reconciliation-container-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.reconciliation-totals-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}

.reconciliation-totals-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.reconciliation-totals-label {
  text-align: end;
  width: 100%;
  font-family: "Roboto Bold" !important;
}

.reconciliation-totals-amount-container {
  width: 120px;
  background-color: white;
  padding: 10px;
}

.media-reconciliation-sheet-actions-root {
  display: grid;
  gap: 20px;
}

.reconciliation-payables-rwt-container {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  width: 100%;
}

.ap-invoice-form-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.ap-invoiceform-columns-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.reconcile-purchase-order-container {
  display: grid;
  gap: 20px;
}

.split-payable-dialog {
  .MuiDialogContent-root {
    padding: 8px 24px !important;
  }

  .default-title {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
  }
}

.reconcile-allocation-cell-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.reconcile-allocation-cell-allocation-link {
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;

  span {
    font-size: 0.55rem !important;
  }
}