.add-new-schedule-item-form-root {
  display: flex;
  gap: 15px;
  flex: 1;
  padding: 20px 20px 10px 20px;
}

.add-new-schedule-item-form-flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1;
}
.add-new-schedule-item-form-field {
  flex: 1 1 calc(33.33% - 10px); // Take up 1/3rd of the width, minus gap spacing
}
.add-new-schedule-item-form-action-container {
  flex: 0.1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
