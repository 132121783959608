.saveButton {
  display: flex !important;
  justify-content: flex-end !important;
  margin: 10px !important;
}

.safety-brief-text-editor {
  height: calc(100vh - 460px) !important;
  padding: 0px 15px 15px 15px;
}

.add-contact-modal-appbar {
  background-color: #000000;
  border-radius: 5px;
  color: white;
  width: 400px;
}
.add-contact-modal-text-input-container {
  display: flex;
  flex-direction: column;
  width: 400px;
}
.modal-text-input {
  margin: 5px !important;
}
.add-new-hazard-btn {
  margin: 5px !important;
}
.add-contact-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.h-and-s-contact-autocomplete {
  margin: 5px !important;
}

.remove-from-hands-list {
  cursor: pointer !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
