.media-campaigns-root {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 20px;
}

.media-campaigns-section-paper{
  display: grid;
  gap: 15px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 20px;
}

.media-spend-allocation-header-container {
  display: grid;
  grid-template-columns: 1fr 0.6fr 0.4fr 2fr;
  gap: 10px;
  align-items: end;
}

.media-spend-allocation-body-container {
  display: grid;
  padding: 10px;
  gap: 5px;
}

.media-spend-allocation-container-title{
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: #000000;
}

.media-spend-allocation-row {
  display: grid;
  grid-template-columns: 1fr 0.6fr 0.4fr 2fr;
  gap: 5px;
  :hover {
    background-color: #ededed !important;
  }

  .media-spend-butget-column-container {
    max-width: 675px;
    
  }
  
  .add-background {
    background-color: white;
    cursor: pointer;
    display: flex;
  }
  .text-alignment {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

.media-spend-allocation-paper {
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  background: #f8f8f8 !important;
}

.media-spend-allocation-container {
  display: flex;
  flex: 1;
  font-size: 9px !important;
  gap: 5px;
  :hover{
    background-color: #b1b1b1  !important;
    color: #000 !important;
  }

  .MuiTypography-body1 {
    font-size: 10px;
  }

  .MuiFilledInput-root {
    border-radius: 0px !important;
  }

  .MuiFormLabel-root {
    color: #7c7c7c !important;
    font-size: 10px;
    line-height: 7px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .budget-allocation-container {
    // width: 91px;
    flex: 1;
    min-width: 70px;
    margin-bottom: 5px;
  }

  .budget-label-container {
    color: #7c7c7c;
    font-size: 10px;
    line-height: 7px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 500 !important;
  }
}

.media-spend-allocation-root {
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #4259eb;
  }
  .MuiFilledInput-root:before {
    border-bottom: 0px !important;
  }

  .MuiFilledInput-root:after {
    border-bottom: 0px !important;
  }

  .MuiDialog-paper {
    background-color: #f3f3f3;
  }

  input {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 10px;
  }
  display: grid;
  gap: 5px;
}

.media-spend-budget-allocation-input-root {
  display: flex;
  justify-content: start;
  padding: 10px;
  background-color: #ededed;
  min-height: 15px;
  border: 1px solid #d9d9d9;
  color: #7c7c7c;
  
}

.media-spend-budget-allocation-input-invoice-pending{
  border: 1px solid #ffa500;
  background: #fdda98;
}

.media-spend-budget-allocation-cost-view{
  color: #D10000 !important;
}

.media-spend-budget-allocation-input-supplier-cost-view{
  border: 1px solid #D10000 !important;
  background: #FFECEC !important;
  color: #D10000;
}
.media-campaigns-filter-input{
  border: 1px solid white !important;
  background: white !important;
  border-radius: 0px !important;
  input {
    font-size: 12px !important;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink{
    color: #000000 !important;
  }
  .MuiFilledInput-root{
    background: white !important;
  }
  .MuiFilledInput-root.Mui-focused{
    background: white !important;
  }
}
.media-campaigns-filter-root{
  padding: 5px 0px;
}