.glow {
  /* color: #fff; */
  color: #fff;
  text-align: center;
  -webkit-animation: neon3 1.5s ease-in-out infinite alternate;
  -moz-animation: neon3 1.5s ease-in-out infinite alternate;
  animation: neon3 1.5s ease-in-out infinite alternate;
}

@keyframes neon3 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #00b2e2, 0 0 30px #00b2e2,
      0 0 40px #00b2e2, 0 0 70px #00b2e2, 0 0 80px #00b2e2, 0 0 100px #00b2e2,
      0 0 150px #00b2e2;
  }

  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #00b2e2, 0 0 15px #00b2e2,
      0 0 20px #00b2e2, 0 0 35px #00b2e2, 0 0 40px #00b2e2, 0 0 50px #00b2e2,
      0 0 75px #00b2e2;
  }
}

.blueSwitch span.MuiSwitch-thumb {
  color: #3f51b5 !important;
}

.blueSwitch .MuiSwitch-track {
  background-color: #3f51b5;
}

.talent-agency-card-click {
  /* border: 1px solid rgb(200, 200, 200);  */
  border: 1px solid #00b2e2;
  padding: 13px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  width: "auto";
  min-width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.talent-agency-card-click-error {
  border: 1px solid #f7313a;
  padding: 13px;
  border-radius: 5px;
  font-size: 14px;
  width: "auto";
  min-width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.talent-agency-card-click-disabled {
  border: 1px solid rgba(0, 0, 0, 0.38);
  padding: 13px;
  border-radius: 5px;
  font-size: 14px;
  width: "auto";
  min-width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
}

.add-agent-fab {
  background-color: #00b2e2 !important;
  color: white !important;
  margin-bottom: "auto";
  margin-left: "auto";
  margin-right: "0px";
  margin-top: "auto";
  min-width: "40px";
  max-width: "40px";
}

.contractor-dialog {
  .MuiDialogContent-root {
    padding: 0px;
    min-height: 490px;
  }
}

.contractor-pop-up-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.contractor-pop-up-modal-left-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 70%;
}

.add-agentpop-up-new-schedule-item {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* width: 130%; */
}

.add-agent-popup-currency-section {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  gap: 10px;
  width: 100%;
  padding: 7px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.add-agent-pop-up-edit-talent-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* width: 155%; */
}

.clickable {
  color: blue !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.history-table-header {
  /* position: sticky !important;
  top: 0px !important; */
}

.action-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 5px 24px;
}