.bills-dashboard-total-cards-root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  align-items: center;
}
.bills-dashboard-total-cards-container {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
}

.bills-dashboard-total-cards-amount {
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 700 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  @media (max-width: 1720px) {
    font-size: 1.5rem;
  }
}

.bills-dashboard-total-cards-description{
    display: flex;
    flex-direction: column;
}
.bills-dashboard-total-cards-description-link{
  text-decoration: underline;
  cursor: pointer;
}