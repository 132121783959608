.custom-accordion-root {
  padding: 20px;
}
.custom-accordion-title-container {
  display: flex;
  gap: 15px;
  h6 {
    color: #524d4d !important;
    font-size: 20px;
  }
}
.custom-accordion-title-alert {
  h6 {
    color: red !important;
  }
  svg {
    fill: red;
  }
}
