.people-of-influence-form-root {
    width: 100%;
  }
  
  .people-of-influence-form-wrapper {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .people-of-influence-form-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
  }

  .people-of-influence-form-multiline-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  .people-of-influence-form-container-inputs {
    display: flex;
    gap: 10px;
    @media (max-width: 1080px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .people-of-influence-form-container-date-input {
    max-width: 180px;
    @media (max-width: 1080px) {
      max-width: unset;
    }
  }
  
  .people-of-influence-form-container-campaign-input {
    min-width: 250px;
    @media (max-width: 1080px) {
      min-width: unset;
    }
  }
  .people-of-influence-form-container-pull-from-input {
    max-width: 250px;
    @media (max-width: 1080px) {
      max-width: unset;
    }
  }
  
  .people-of-influence-form-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-top: 15px;
  }
  
  .people-of-influence-form-flex-container{
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
  }
  
  .people-of-influence-form-link-btn{
      text-transform: none !important;
  }