.custom-payable-table-row-details {
  min-height: 75px;
  display: flex;
  flex-direction: column;
}
.custom-payable-table-row-details-wrapper {
  background-color: #e4e4e4;
}
.custom-payable-table-row-field-wrapper {
  display: flex;
  gap: 5px;
  svg {
    font-size: 1rem !important;
  }
}

.custom-payable-table-row-link {
  text-decoration: underline;
  cursor: pointer;
}

