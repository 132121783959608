.client-budget-root {
  margin-top: 20px;
}

.client-budget-accordion-container {
  .MuiAccordionSummary-root {
    background: rgb(0, 178, 226) !important;
  }

  .MuiAccordionSummary-content {
    color: #fff;
    display: flex;
    justify-content: center;
  }

  .MuiAccordionSummary-root svg {
    fill: #fff !important;
  }
}
.client-budget-table-category {
  width: 100%;
  .MuiTableCell-head {
    font-weight: 700 !important;
  }
}
.client-budget-parent-table-icon-column {
  padding: 0px !important;
}
.client-budget-table-icon-column {
  width: 30px !important;
  padding: 0px !important;
}
.client-budget-table-sub-table{
  display: grid;
  grid-template-columns: 0.6fr 0.6fr 0.33fr 0.26fr 0.3fr;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding: 5px;
}

.client-budget-table-category-column{
  max-width: 350px;
}

.client-budget-table-link{
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.client-budget-accordion-content-container{
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}
.client-budget-table-flex-container{
  display: flex;
  gap: 5px;
}
.client-budget-subtable-icon-column{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}