.client-tabs-root-container{
    padding: 0 20px;
}

.client-tabs-main-container{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}
.client-tabs-display-none{
    display: none !important;
}