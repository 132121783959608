.rwt-report-root{
    width: 100%;
}
.rwt-report-title-container{
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
}
.rwt-report-table-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
}