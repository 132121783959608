.scheduleLocationCard
  div.MuiPaper-root:nth-child(4)
  > header:nth-child(1)
  > div:nth-child(2)
  > div:nth-child(2) {
  margin: 0;
  margin-top: -5px;
  font-size: 12px;
}
.scheduleLocationCard
  div.MuiPaper-root:nth-child(3)
  > header:nth-child(1)
  > div:nth-child(4)
  > div:nth-child(2) {
  margin: 0;
  margin-top: -5px;
  font-size: 12px;
}
.scheduleLocationCard div.MuiFormControl-root:nth-child(3) > div:nth-child(2) {
  margin: 0;
  margin-top: -5px;
  font-size: 12px;
}
.scheduleLocationCard div.MuiFormControl-root:nth-child(4) > div:nth-child(2) {
  margin: 0;
  margin-top: -5px;
  font-size: 12px;
}
.scheduleLocationCard
  div.MuiPaper-root:nth-child(4)
  > header:nth-child(1)
  > div:nth-child(4)
  > div:nth-child(2) {
  margin: 0;
  margin-top: -5px;
  font-size: 12px;
}
.scheduleLocationCard
  div.MuiPaper-root:nth-child(4)
  > header:nth-child(1)
  > div:nth-child(3)
  > div:nth-child(2) {
  margin: 0;
  margin-top: -5px;
  font-size: 12px;
}
.scheduleLocationCard
  div.MuiPaper-root:nth-child(3)
  > header:nth-child(1)
  > div:nth-child(2)
  > div:nth-child(2) {
  margin: 0;
  margin-top: -5px;
  font-size: 12px;
}
.scheduleLocationCard
  div.MuiPaper-root:nth-child(3)
  > header:nth-child(1)
  > div:nth-child(3)
  > div:nth-child(2) {
  margin: 0;
  margin-top: -5px;
  font-size: 12px;
}

.scheduleLocationCard #time-picker-button {
  padding: 0;
  margin: 0;
  width: 12px;
}
.scheduleLocationCard #time-picker-icon {
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
}

.scheduleLocationCard .input-label-schedule-location {
  font-size: 10px !important;
  margin-top: -8px !important;
}
/* .scheduleLocationCard #start-time-picker-label {
  font-size: 10px !important;
  margin-top: -8px !important;
} */
/* .scheduleLocationCard #end-time-picker-label {
  font-size: 10px !important;
  margin-top: -8px !important;
} */
.animate-search {
  animation: blinkingSearch 1.2s infinite;
}
@keyframes blinkingSearch {
  0% {
    background-color: green;
  }
  49% {
    background-color: green;
  }
  60% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: green;
  }
}
.scheduleLocationCard
  .MuiCollapse-entered
  > div:nth-child(1)
  > div:nth-child(1)
  > div:nth-child(1)
  > div:nth-child(1)
  > div:nth-child(3)
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(1)
  > p:nth-child(1)
  > div:nth-child(1)
  > div:nth-child(1) {
  margin-left: -2px;
}

.express-payment-location {
  margin: 3px 0px -20px 0px;
  padding: 2px 5px 2px 5px;
  background-color: #c2f0c2;
  font-size: 0.65em;
  color: #2d2d2d;
}
