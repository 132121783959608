.emailer-div {
    position: relative;
}

.saveButton {
    align-self: center !important;
}

.brandCover {
    position: absolute !important;
    z-index: 99999 !important;
    top: 94vh;
    left: 65vw;
    width: 428px !important;
    height: 58px !important;
    background-color: grey !important;
}

.selectedEmailTemplate {
    background-color: #c2f0c2 !important;
  }