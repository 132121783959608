.update-influencer-form-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.update-influencer-form-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.update-influencer-form-title {
  display: grid;
  grid-template-columns: 1fr;
}
.update-influencer-form-double-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.update-influencer-form-social-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.update-influencer-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  padding: 5px 10px;
}

.update-influencer-form-select-agent-container {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  gap: 10px;
}

.update-influencer-form-fab {
  background: #00b2e2 !important;
  color: white !important;
  margin-bottom: auto !important;
  margin-left: auto !important;
  margin-right: 0px !important;
  margin-top: auto !important;
  min-width: 40px !important;
  max-width: 40px !important;
}
.update-influencer-form-switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
.update-influencer-form-currency-wrapper {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  gap: 10px;
}
.update-influencer-switch-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.update-influencer-form-currency-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
