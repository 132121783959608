.influencer-deliverable-item-accordion-details {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
}

.influencer-deliverable-flex-container {
  display: flex;
  gap: 10px;
}
.influencer-deliverable-flex-container-item {
  flex: 1;
  min-width: 0;
}
.influencer-deliverable-flex-container-item-half-width {
  flex: 0.5;
  min-width: 0;
}
.influencer-deliverable-flex-container-item-double-width {
  flex: 2;
  min-width: 0;
}
.influencer-deliverable-item-body-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  align-items: end;
  justify-items: flex-start;
  width: 100%;
}

.influencer-deliverable-item-body-files-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: end;
  justify-items: flex-start;
  width: 100%;
}

.influencer-deliverable-item-uploaded-file-details {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 5px !important;
}
.deliverable-action-button-contaner-existing {
  padding: 5px;
}
.deliverable-action-button-contaner-new {
  display: flex;
  justify-content: flex-end;
}
.upload-deliverables-upload-button {
  display: none;
}
.influencer-deliverable-item-uploaded-file-status {
  display: flex;
  gap: 5px;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 8px !important;
  line-height: 14px;
  letter-spacing: 0.12px;
  color: #299965 !important;
  svg {
    width: 14px !important;
    fill: #299965 !important;
  }
}
.influencer-deliverable-item-uploaded-file-name {
  font-style: normal;
  font-weight: 400;
  font-size: 10px !important;
  line-height: 14px;
  letter-spacing: 0.12px;
  color: #79747e !important;
}
.influencer-deliverable-item-line-asset {
  min-width: 310px;
}
.influencer-deliverable-item-action-container {
  display: flex;
  justify-content: flex-end;
}
.influencer-deliverable-item-field-fullwidth {
  width: 100%;
  height: 100%;
}
.influencer-deliverable-item-link-wrapper {
  display: flex;
  gap: 5px;
  justify-content: end;
  position: relative;
}
.influencer-deliverable-item-link-field {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  height: 100%;
}
.influencer-deliverable-item-field-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;
  height: 100%;
}

.influencer-deliverable-item-field-grid-span2 {
  grid-column: 1 / span 2;
  height: 100%;
}
.influencer-deliverable-item-field-grid-content-creator-date {
  grid-column: 3 / 5;
  height: 100%;
}

.influencer-deliverable-item-go-live-date {
  grid-column: 3 / span 2;
  height: 100%;
  width: 100%;
}

.influencer-deliverable-item-flex-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.influencer-deliverable-item-accordion-title {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  width: 100%;
}
.deliverableItem-filePreview-container {
  width: 100px;
}
