.project-campaign-details-root{
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    width: 100%;
}

.project-campaign-details-button-container{
    display: flex;
    justify-content: flex-start;
}

.load-from-previous-campaign-button {
    max-width: 300px;
}