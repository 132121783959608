.influencers-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
}

.influencers-header-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.influencers-search-bar-container {}

.influencers-filter-container {
  display: flex;
}

.influencers-no-records-container {
  display: flex;
  justify-content: center;
}

.influencers-create-new-container {
  display: flex;
  justify-content: flex-end;
}

.influencers-create-new-button-container {
  margin-left: 5px;
  margin-right: 5px;
}

.influencers-lookup-table-root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}

.influencers-lookup-card-container {
  padding: 20px;
}

.influencers-lookup-card-inner-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.influencers-lookup-card-action-container {
  display: flex;
  justify-content: center;
}

.poi-add-to-campaign-button {
  color: black !important;
  background-color: white !important;
  border: 0px !important;
  font-weight: 600 !important;
}

.poi-add-to-campaign-button-padding {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.influencer-filter-button-container {
  padding: 10px;

  .MuiSelect-select {
    min-width: 160px;
    max-width: 160px;
    background-color: white !important;
  }

  .chips {
    display: flex;
    flex-wrap: wrap;
  }

  .chip {
    margin: 2px;
    width: auto;
  }

  .MuiListItem-root.Mui-selected {
    background-color: white !important;
  }

  .Mui-focused {
    background-color: white !important;
  }
}

.filter-button {
  background-color: white;
  color: black !important;
  font-size: 12px !important;
  font-family: 'Roboto';
  position: relative;
  display: inline-block;
  padding: 3px 12px;
  border-radius: 8px;
  font-weight: 700;
  margin-right: 5px;
}

.filter-checkbox {
  color: black !important;
  border-color: grey !important;
}

.filter-button-filled {
  font-family: 'Roboto';
  display: flex;
  align-self: center;
  padding: 8px 12px;
  border-radius: 8px;
  margin-right: 5px;
  background-color: black !important;
  color: white !important;
  font-size: 10px !important;
}

.filter-button:hover {
  cursor: pointer;
}

.filter-button-filled:hover {
  cursor: pointer;
}