.smsSaveButton {
  display: flex !important;
  justify-content: flex-end !important;
  margin: 10px !important;
}

.selectedSms {
  background-color: #c2f0c2 !important;
}

