.awaiting-deposit {
    font-style: italic !important;
    background-color: #ff4d4d !important;
    color: white !important;
    height: 40px !important;
}

.overdue-outputs-header {
    font-style: italic !important;
    background-color: #ff4d4d !important;
    color: white !important;
    height: 40px !important;
    margin-bottom: 10px !important;
    line-height: 1.5 !important;
}