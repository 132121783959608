.org-homepage-container {
  max-width: 100vw;
  padding: 0px 20px 10px 20px;
  line-height: 25px;
  letter-spacing: 0.15px;
}

.org-homepage-header {
  font-family: Roboto, Arial;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 5px;
}

.org-homepage-subheader {
  font-family: Roboto;
  font-size: 1.2rem;
  margin-top: 5px;
}

.org-homepage-body {
  width: 100%;
}

.org-homepage-search-header {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .column-1 {
    flex: 1;
  }
}

.homepage-client-card {
  background-color: var(--primary-color);
  width: 100%;
  border-radius: 10px;
  overflow: auto;
  margin-top: 10px;
}

.homepage-client-card-header {
  font-family: Roboto, Arial;
  color: var(--reverse-primary-color);
  font-weight: bold;
  font-size: 1rem;
  padding: 10px;
}

.homepage-client-job-header {
  font-family: Roboto, Arial;
  color: black;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px;
}

.homepage-client-card-footer {
  font-family: Roboto, Arial;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;

  .show-influencers-text {
    display: flex;
    justify-content: left;
  }

  .hide-project-text {
    display: flex;
    justify-content: right;
  }
}

.homepage-client-card-body {
  padding: 10px;
  margin: 0px 10px 10px 10px;
  background-color: #f8f8f8;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}

.homepage-project-row-container {
  display: flex;
  margin-top: 5px;
}

.homepage-project-row-project-title-container {
  font-family: Roboto, Arial;
  min-width: 20%;
  max-width: 20%;
  background-color: #ffffff;
  margin-right: 2.5px;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
}

.homepage-project-row-project-title {
  height: 100%;
  width: 100%;
  text-align: center;
  align-content: space-evenly;
  display: block;
}

.homepage-project-row-department {
  text-align: center;
  display: block;
}

.homepage-project-row-stepper-container {
  font-family: Roboto, Arial;
  min-width: 79%;
  max-width: 79%;
  background-color: #ffffff;
  margin-left: 2.5px;
  border: 1px solid var(--secondary-color);
  border-radius: 10px;

  .MuiStepper-root {
    border-radius: inherit;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #43a635;
  }

  .connector-alternativeLabel {
    top: 10px;
    left: calc(-50% + 16px);
    right: calc(50% + 16px);
  }

  .connector-active .connector-line {
    border-color: #43a635;
  }

  .connector-completed .connector-line {
    border-color: #43a635;
  }

  .connector-line {
    border-color: #eaeaf0;
    border-top-width: 3px;
    border-radius: 1px;
  }

  .MuiStepLabel-iconContainer:hover {
    cursor: pointer !important;
  }
}
