.time-tracker-root {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-top: 20px;
}

.time-tracker-heading-container{
  display: flex;
  gap: 10px;
  align-items: center;
}

.time-tracker-allocation-root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  background: #f8f8f8 !important;
}
.time-tracker-allocation-filters-root {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.time-tracker-filter-input {
  border: 1px solid white !important;
  background: white !important;
  border-radius: 0px !important;
  input {
    font-size: 12px !important;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #000000 !important;
  }
  .MuiFilledInput-root {
    background: white !important;
  }
  .MuiFilledInput-root.Mui-focused {
    background: white !important;
  }
}

.time-tracker-hour-allocation-column-container {
  max-width: 675px;
}

.time-tracker-project-name-container {
  display: flex;
  flex: 0.7;
  gap: 5px;
  font-size: 9px !important;
}

.time-tracker-allocation-container {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 9px !important;
  gap: 5px;
  :hover {
    cursor: pointer;
  }

  .MuiFilledInput-root {
    border-radius: 0px !important;
  }

  .MuiFormLabel-root {
    color: #7c7c7c !important;
    font-size: 10px;
    line-height: 7px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .time-tracker-time-allocation-container {
    flex: 1;
    min-width: 70px;
    text-align: center;
    input {
      text-align: center;
      font-size: 12px;
    }
  }
}
.time-tracker-hour-allocation-row {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 5px;
  .media-spend-butget-column-container {
    max-width: 675px;
  }

  .add-background {
    background-color: white;
    display: flex;
    height: auto;
    flex: 0.7;
    height: 100%;
    min-height: 43px;
  }
  .text-alignment {
    display: flex;
    align-items: center;
  }
}

.time-tracker-project-name {
  margin: 0 15px !important;
}
.time-tracker-allocation-table-root {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.time-tracker-caption-label-root {
  flex: 1;
  min-width: 50px;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-tracker-caption-label-text {
  color: #7c7c7c;
  font-size: 0.5rem !important;
  text-transform: uppercase;
  font-weight: 500 !important;
}
.time-tracker-hour-allocation-row-project-name-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.time-tracker-hour-allocation-row-close-project {
  opacity: 0.2;
  color: #7c7c7c;
  margin-right: 10px;
  &:hover {
    opacity: 0.7 !important;
    text-decoration: underline;
    cursor: pointer;
  }
}
