.content-approved-dialog-body-container {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.content-approved-dialog-body-influencer-name {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  align-items: center;
  justify-items: center;
}
