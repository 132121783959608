.heading-container-root {
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        color: #000000;
        font-size: 20px;
        font-weight: 650;
        font-family: "SF-Pro", "Roboto";
        padding: 10px;
    }
}