.check-in-table-body-cell {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.check-in-table-header-cell {
  font-family: Arial, Helvetica, sans-serif !important;
  height: 40px !important;
  background-color: white !important;
  color: black !important;
  font-weight: "bold" !important;
  border: 1px solid black !important;
}

.acknowledge-header-cell {
  width: 180px !important;
}

.sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
}

#check-in-table .MuiTableRow-hover:hover {
  background-color: #ade6e6;
}
