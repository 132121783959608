.bills-dashboard-root{
}

.bill-dashboard-expense-table-container{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}
.bill-dashboard-transfer-btn-container{
    display: flex;
    justify-content: flex-end;
}