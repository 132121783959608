.currency-exchange-form-root-container {
  width: 100%;
}
.currency-exchange-form-calculator-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
}
.currency-exchange-form-rates-label-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.currency-exchange-form-rates-label-container{
    display: flex;
    gap: 10px;
}

.currency-exchange-form-input-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
.currency-exchange-form-input-container {
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  align-items: center;
  li {
    list-style-type: none !important;
  }
  .MuiAvatar-root{
    width: 24px !important;
    height: 24px !important;
  }
}

.currency-exchange-form-select-currency {
  background-color: unset;
  border: 0;
  text-align: center;
  li {
    list-style-type: none !important;
  }
}
.currency-exchange-form-select-currency:focus-visible {
  outline: none;
}
.currency-exchange-form-select-currency-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .MuiAvatar-root{
    width: 24px !important;
    height: 24px !important;
  }
}
.currency-exchange-form-autocomplete {
  .MuiInput-underline:before {
    border-bottom: none !important;
    
  }

  

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-height: 40px;
  }
}
.currency-exchange-form-currecy-dropdown{
    cursor: pointer;
    display: flex;
    gap: 10px;
}

.currency-exchange-form-flex-end-container{
    display: flex;
    justify-content: flex-end;
}