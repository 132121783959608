.tv-spotlist-dialog-action-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}
.tv-spotlist-dialog-record-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 15px;
}
.autocomplete-full-width {
  width: 180px;
}
.tv-spotlist-dialog-record-card {
  padding: 20px;
  background-color: white;
  border: 1px #ababab solid;
  border-radius: 10px;
  display: grid;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
