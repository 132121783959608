/* Fee */
input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart {
  font-size: 1em !important;
}

div.schedule-item-category .MuiAutocomplete-inputRoot {
  margin-top: 20px !important;
}

div.schedule-item-provider .MuiAutocomplete-inputRoot {
  margin-top: 20px !important;
}

div.start-time-picker .MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* div.scheduleItem .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
    font-size: 14px !important;
    color: red !important;
}

div.scheduleItem .MuiSvgIcon-root {
    height: 0.8em;
    width: 0.8em;
    color: red !important;
} */

div.end-time-picker .MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.role-field {
  min-width: 80px !important;
}

.provider-cell {
  width: 180px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  font-size: 14px !important;
}

/* ------------------ */
div.start-time-picker .MuiInputBase-input {
  font-size: 1em !important;
}

div.end-time-picker .MuiInputBase-input {
  font-size: 1em !important;
}

.time-picker-width {
  min-width: 100px !important;
  max-width: 100px !important;
}

.agent-icon-responsive {
  width: 35 !important;
  height: 35 !important;
}

.responsive-schedule-item-header {
  font-size: 0.7rem !important;
  font-weight: "bold";
  padding-left: "10px";
  padding-right: "2px";
}

.contract-button-group {
  width: 120px !important;
}

.contract-button-text {
  font-size: 1em !important;
}

.ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: block !important;
}

@media screen and (max-width: 1580px) {
  div.talent-start-time-picker .MuiInputBase-input {
    font-size: 0.8em !important;
  }

  div.talent-end-time-picker .MuiInputBase-input {
    font-size: 0.8em !important;
  }

  .talent-time-picker-width {
    min-width: 85px !important;
    max-width: 85px !important;
  }

  .fee-responsive
    input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart {
    font-size: 0.8em !important;
  }

  .provider-cell-responsive {
    /* width: 150px !important; */
    /* width: 120% !important; */
    min-width: 100px !important;
    font-size: 1em !important;
  }

  .agent-icon-responsive {
    width: 20 !important;
    height: 20 !important;
  }

  .role-cell-body {
    font-size: 0.8rem !important;
  }

  .budget-account-cell-body {
    font-size: 0.8rem !important;
    width: 80px !important;
  }

  .client-name-cell-body {
    font-size: 0.8rem !important;
  }

  div.start-time-picker .MuiInputBase-input {
    font-size: 0.8em !important;
  }

  div.end-time-picker .MuiInputBase-input {
    font-size: 0.8em !important;
  }
}
/* ------------------ */
.MuiInputBase-input .MuiInput-input {
  font-size: 12 !important;
}
.express-payment-new-item {
  padding: 2px 5px 2px 5px;
  background-color: #c2f0c2;
  margin-left: -205px;
  font-size: 0.8em;
  position: initial;
  color: #2d2d2d;
  width: 300px;
  margin-bottom: -20px;
}

.OfflineBoltIcon-icon {
  color: #c2f0c2;
  background: #2d2d2d;
  border-radius: 50%;
}

.schedule-sent-chip {
  background-color: rgb(0, 128, 0) !important;
  color: white !important;
  align-self: center;
}

.schedule-not-sent-chip {
  background-color: #f4772e !important;
  color: white !important;
  align-self: center;
}

.schedule-sent-icon {
  color: #4dfa59 !important;
  align-self: center;
}

.schedule-not-sent-icon {
  color: #ed9c91 !important;
  align-self: center;
}

.talent-notes-incomplete {
  background-color: rgb(200, 200, 200) !important;
  color: white !important;
  margin-right: 5px !important;
}

.talent-notes-completed {
  background-color: rgb(37, 223, 50) !important;
  color: white !important;
  margin-right: 5px !important;
}

.generate-schedule-text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  font-size: 30px;
  font-family: Arial;
  margin: 0;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.dot {
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

.loading-dots span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  margin: 2px;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

.budget-table-outer-tree {
  height: 40px !important;
}

.budget-table-inner-tree {
}

.budget-item-header-grid-container {
  font-weight: bold !important;
  font-size: medium !important;
  height: 25px !important;
}

.budget-item-footer-grid-container {
  font-weight: 600 !important;
  font-size: 1em !important;
  height: 25px !important;
}

.budget-item-inner-tree-container {
  text-align: left !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.budget-item-footer-root {
  background-color: #ccf2ff !important;
}

.inner-tree-node-header {
  font-weight: bold !important;
  font-style: italic !important;
  text-align: left !important;
}

.expanded-tree {
  background-color: white !important;
}

.outer-root-tree:hover {
  background-color: #ccffcc;
}

.inner-root-tree:hover {
  background-color: #ccffcc;
}

.outer-root-tree:nth-child(even) {
  background-color: #efefef;
}

.outer-root-tree:nth-child(even):hover {
  background-color: #ccffcc;
}

.inner-root-tree:nth-child(even) {
  background-color: #efefef;
}

.inner-root-tree:nth-child(even):hover {
  background-color: #ccffcc;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-image {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  max-width: 100%;
  max-height: 100%;
}

/* --------------------------- */

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover */
.flip-card {
  background-color: transparent;
  width: 1000px;
  height: 600px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-rotate {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  -webkit-backface-visibility: none !important; /* Safari */
  backface-visibility: none !important;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

/* --------------------------- */