@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "Px Grotesk";
  src: local("Px Grotesk"),
    url("./assets/fonts/PxGroteskRegular.woff2") format("woff2");
}

@font-face {
  font-family: "Mier A";
  src: local("Mier A"), url("./assets/fonts/MierA-Regular.woff") format("woff");
}

@font-face {
  font-family: "Mier A Bold";
  src: local("Mier A Bold"),
    url("./assets/fonts/MierA-ExtraBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Bold";
  src: local("Poppins Bold"),
    url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat Bold"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "SF Pro";
  src: local("SF Pro"), url("./assets/fonts/SF-Pro.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Bold";
  src: local("SF Pro Bold"), url("./assets/fonts/SF-Pro.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto Bold"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}

/* Provide sufficient contrast against white background */
body {
  margin: 0 !important;
  background: var(--background-color);
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.nav-button-menu {
  width: auto;
  height: auto;
  box-sizing: border-box;
  position: relative;
  display: none;
}

.nav-header-container {
  overflow: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
}

.nav-button-home {
  width: auto;
  height: auto;
  box-sizing: border-box;
  position: relative;
}

.layout-background {
  background: var(--background-color);
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.responsive-no-script {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 100%;
  border: 0;
  text-align: center;
}

.responsive-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.component__skeleton {
  display: inline-block;
  background-color: var(--color__gray--lighter);
  padding-bottom: var(--spacing__vertical--1);
}

.component__skeleton--medium {
  width: 33%;
}

.component__skeleton--large {
  width: 100%;
}

.component__image--skeleton {
  padding-bottom: 100%;
  background-color: var(--color__gray--lighter);
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}

.inputProps {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiBadge-colorSecondary {
  background-color: #ed9c91 !important;
}

.day-circle {
  background-color: #ed9c91 !important;
}

.fullscreen-button {
  position: fixed !important;
  z-index: 1000 !important;
  left: 85% !important;
  max-height: 60px;
  min-width: 170px;
}

.margin-sides-10 {
  margin-right: 10px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.hide {
  display: none !important;
}

.float-right {
  float: right !important;
}

.responsive-container-script {
  position: relative;
  /* overflow: hidden; */
  /* padding-top: 38%; */
  /* height: calc(100vh - 750px); */
  height: calc(100vh - 280px);
}

.responsive-container-slides {
  position: relative;
  /* overflow: hidden;
  padding-top: 38%; */
  height: calc(100vh - 280px);
}

/* a.blockbuilder-branding {
  visibility: hidden !important;
}

.brand {
  visibility: hidden !important;
} */

.new-script-button-create {
  margin-right: 10px !important;
}

.new-script-button-cancel {
  margin: 0px 10px !important;
}

.custom-table {
  width: 100%;
  border-spacing: 0;
}

.custom-table-striped tr:nth-child(even) {
  background-color: #f2f2f2;
}

.custom-table-hover tr:hover {
  color: black;
  background-color: #cccccc;
}

.custom-table-border td {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.custom-text-editor-wrapper {
  background-color: white;
  border-width: 0px 1px 1px 1px;
  margin-top: 0px !important;
  padding-top: 0px !important;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.23);

  .rdw-editor-toolbar {
    border: 0px !important;
    border-top: 1px solid #d1d2db !important;
    padding-bottom: 0px !important;
  }

  .rdw-option-wrapper {
    border: 0px !important;
  }

  .rdw-dropdown-wrapper {
    border: 0px !important;
  }

  img {
    width: 11px;
  }
}

.custom-text-editor {
  padding: 15px !important;
}

// .roboto-regular {
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
// }

// .roboto-bold {
//   font-family: "Roboto Bold", "Helvetica", "Arial", sans-serif !important;
// }

.highlight-border-light-blue {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(81, 203, 238, 1);
}

.highlight-border-red {
  box-shadow: 0 0 5px rgb(255, 69, 69);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid rgb(255, 69, 69);
}

.default-checkbox {
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #000000 !important;
  }
}

.default-disabled-button {
  background-color: #d1d1d1 !important;
  color: #9b9b9b !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-size: 10px !important;
}

.default-button {
  background-color: black !important;
  color: white !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-size: 10px !important;
}

.default-button-green {
  background-color: rgb(0, 158, 45) !important;
  color: white !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-size: 10px !important;
  border: 1px solid rgb(0, 158, 45) !important;
}

.default-button-red {
  background-color: rgb(212, 0, 0) !important;
  color: white !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-size: 10px !important;
  border: 1px solid rgb(212, 0, 0) !important;
}

.default-button-grey {
  background-color: #ebebeb !important;
  color: black !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-size: 10px !important;
  border: 1px solid #d9d9d9 !important;
}

.default-button-grey:hover {
  cursor: pointer;
}

.default-button-typography {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 600;
  line-height: 16.022px;
  letter-spacing: 0.334px;
  text-transform: capitalize !important;
  padding: 8px 10px !important;
  font-size: 10px !important;
}

.default-button .MuiButton-label,
.default-button-grey .MuiButton-label {
  padding: 2px;
}

/* Roboto font classes */
.roboto-thin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 100 !important;
  font-style: normal !important;
}

.roboto-thin-italic {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 100 !important;
  font-style: italic !important;
}

.roboto-light {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important;
}

.roboto-light-italic {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300 !important;
  font-style: italic !important;
}

.roboto-regular {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.roboto-regular-italic {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-style: italic !important;
}

.roboto-medium {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.roboto-medium-italic {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  font-style: italic !important;
}

.roboto-bold {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 800 !important;
  font-style: normal !important;
}

.roboto-bold-italic {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 800 !important;
  font-style: italic !important;
}

.roboto-black {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 900 !important;
  font-style: normal !important;
}

.roboto-black-italic {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 900 !important;
  font-style: italic !important;
}

/* End of Roboto font classes */

.no-hover-state {
  pointer-events: none;
}

.navigation-menu-panel-root {
  .MuiTreeItem-content:hover {
    .MuiListItemIcon-root {
      color: white !important;
    }

    .MuiListItemText-root {
      color: white !important;
    }
  }
}

.navigation-menu-footer-container {
  padding: 20px;
  display: grid;
  gap: 5px;

  span {
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px !important;
    font-size: 6px !important;
  }
}

.default-text-field-container {
  .MuiFilledInput-root {
    background-color: #ffffff !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .MuiFilledInput-root:before {
    border-bottom: 0px !important;
  }

  .MuiFilledInput-root:after {
    border-bottom: 0px !important;
  }
}

.default-dialog {
  .MuiFilledInput-root:before {
    border-bottom: 0px !important;
  }

  .MuiFilledInput-root:after {
    border-bottom: 0px !important;
  }

  .MuiDialog-paper {
    background-color: #f3f3f3;
  }

  .MuiDialogTitle-root {
    padding: 0 24px !important;
  }

  .MuiDialogContent-root {
    padding: 20px 24px !important;
  }

  input {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }

  .close-icon-container {
    display: flex;
    justify-content: end;
    padding: 5px 10px;

    .MuiSvgIcon-root {
      color: black;
    }
  }

  .default-dialog-header-container {
    padding-top: 10px !important;
  }

  .default-title {
    font-size: 25px !important;
    font-weight: 800 !important;
    letter-spacing: 0.25px !important;
  }

  .default-description {
    font-size: 14px !important;
    color: #767676;
    letter-spacing: 0.17px !important;
  }

  .default-action-container {
    display: flex;
    justify-content: end;
    gap: 5px;
  }
}

.text-green {
  color: #299965;
}

.text-red {
  color: rgb(196, 38, 38);
}

.hidden {
  display: none;
}

.action-button {
  font-family: 'Roboto';
  display: flex;
  align-self: center;
  padding: 8px 12px;
  border-radius: 8px;
  margin-right: 5px;
  color: black !important;
  border: 1px solid #000000;
  font-size: 11px !important;
}

.action-button:hover {
  cursor: pointer;
}

.shared-header-root{
  padding: 10px;
}

.default-background-container{
  background-color: var(--secondary-color) !important;
  color: var(--text-color) !important;
}

.ts-default-table td {
  padding: 0px 15px;
  max-height: 50px;
}
