.places-autocomplete-input {
  height: 30px;
  width: 100%;
  font-size: large;
}

.labels-newlocation {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.stepper-buttons-div {
  position: relative;
  float: right;
}

/* .stepper-buttons-div {
    position: absolute;
    bottom: 0;
    right: -15px
} */